import * as React from 'react'
import {
  Pagination,
  DateField,
  TextField,
    Datagrid,
  ReferenceManyField,
} from 'react-admin'
import ProfileLinkField from 'src/components/fields/ProfileLinkField'

interface TitleProps {
  record?: any;
}

export const ChatMessageList = (props: any) => {
  const {record} = props
  return (
    <ReferenceManyField {...props} reference="chat-message" target="chatId" addLabel={false}
                        sort={{field: 'id', order: 'ASC'}} pagination={<Pagination/>} perPage={50}>
      <Datagrid>
        <TextField source={'id'} label={'ID'}/>
        <DateField source={'createdAt'} label={'Время'} showTime={true} />
        <ProfileLinkField label={'Профиль'} source={'profile'}/>
        <TextField source={'message'} label={'Сообщение'}/>
         <DateField source={'createdAt'} label={'Создан'} showTime={true} />
      </Datagrid>
    </ReferenceManyField>)
}