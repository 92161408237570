import * as React from 'react'
import {
  TabbedForm,
  FormTab,
  SelectInput,
  TextInput,
} from 'react-admin'
import {useFormContext} from 'react-hook-form'
import {ApplicationStatusList} from 'src/types/enum/ApplicationStatus'

const InfoTab = (props: any) => {
  const form = useFormContext()
  return (<FormTab
    label="Информация"
    path={'benefit'}
    {...props}>
    <SelectInput source={'status'} label={'Статус'} choices={ApplicationStatusList} fullWidth variant={'outlined'}/>
    <TextInput source={'coverLetter'} label={'Отклик'} multiline fullWidth variant={'outlined'}/>
    </FormTab>)
}
const ApplicationForm = (props: any) => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab/>
    </TabbedForm>
  )
}
export default ApplicationForm
