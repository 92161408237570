import * as React from 'react'
import classnames from 'classnames'


import GridListTile from '@mui/material/ImageListItem'
import {Link} from 'react-router-dom'
import GridListTileBar from '@mui/material/ImageListItemBar'
import {getMediaPath} from 'src/utils/media'
import styles from './index.module.scss'
import VideoFileIcon from '@mui/icons-material/VideoFile'
import {Confirm, useDataProvider, useRefresh} from 'react-admin'
import {AssetEditModal} from 'src/components/AssetEditModal'
import {useRef, useState} from 'react'
import {AssetType, IAsset} from 'src/components/types'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
interface Props {
    item: IAsset
    isActive?: boolean
    onSelect?: (item: IAsset) => void
}

const AssetListItem = (props: Props) => {

    const {item, isActive} = props
    const [anchorEl, setAnchorEl] = useState(null)
    const [loading, setLoading] = useState(null)
    const [editId, setEditId] = useState<number | null>(null)
    const containerRef = useRef(null)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false)
    const handleCloseMenu = () => {
        setAnchorEl(null)
    }
    const handleSelect = () => {
        setAnchorEl(containerRef?.current)
    }
    const handleDelete = async () => {
        await dataProvider.delete('asset', {id: item.id, previousData: item})

        await refresh()
        setIsDeleteConfirmOpen(false)
        setAnchorEl(null)
    }
    const handleEditOpen = () => {
        console.log('handleEditOpen')
        setAnchorEl(null)
        setEditId(item.id)
    }
    const handleDeleteOpen = () => {
        setAnchorEl(null)
        setIsDeleteConfirmOpen(true)
    }
    const handleDownload = () => {

    }
    const handleCloseEdit = async (asset?: IAsset | null) => {
        console.log('handleCloseEdit', asset)
        if (asset) {
            await refresh()
        }
        setEditId(null)
    }
    return (
        <GridListTile
            className={classnames({[styles.activeItem]: isActive, [styles.withIcon]: item.type !== AssetType.IMAGE})}
            onClick={(e: any) => {
                e.preventDefault()
                if(props.onSelect) {
                    props.onSelect(item)
                }
            }}
            component={Link}
            to={''}

            key={item.id}
        >

            {item.type === AssetType.IMAGE && <img
                src={`${getMediaPath(item.source)}?preset=small&fpx=${item.focalPoint?.x || '0.5'}&fpy=${item.focalPoint?.y || '0.5'}`}
                alt=""/>}
            {item.type === 'VIDEO' && <VideoFileIcon/>}
            <Menu
                id={`asset-menu-${item.id}`}
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={handleCloseMenu}
            >
                <MenuItem onClick={handleEditOpen}>Редактировать</MenuItem>
                <MenuItem onClick={handleDownload}>Скачать</MenuItem>
                <MenuItem onClick={handleDeleteOpen}>Удалить</MenuItem>
            </Menu>
            <GridListTileBar
                className={styles.tileBar}
                title={item.name}
                actionIcon={<IconButton
                    ref={containerRef}
                    className={styles.settings}
                    edge="start"
                    color='primary'
                    onClick={handleSelect}
                    aria-label="settings"
                >
                    <MoreVertIcon />
                </IconButton>}
            />
            {isDeleteConfirmOpen && <Confirm
                isOpen={isDeleteConfirmOpen}
                loading={loading ?? false}
                title={'Удалить?'}
                content={`Вы уверены что хотите удалить файл ${item.name || `#${item.id}`}?`}
                onConfirm={handleDelete}
                onClose={() => setIsDeleteConfirmOpen(false)}
            />}
            {editId && <AssetEditModal id={editId!} isShown={true} onClose={handleCloseEdit}/>}
        </GridListTile>
    )
}


export default AssetListItem
