
export enum ProposalStatus {
    Applied = 'applied',
    Accepted = 'accepted',
    Rejected = 'rejected',
}
export const ProposalStatusList = [
    {id: ProposalStatus.Applied, name: 'Создан'},
    {id: ProposalStatus.Accepted, name: 'Одобрен'},
    {id: ProposalStatus.Rejected, name: 'Отклонен'}
]