import ListIcon from '@mui/icons-material/List'
import TariffList from 'src/resources/tariff/TariffList'
import TariffEdit from 'src/resources/tariff/TariffEdit'
import TariffCreate from 'src/resources/tariff/TariffCreate'

export default {
  create: TariffCreate,
  edit: TariffEdit,
  list: TariffList,
  icon: ListIcon,
}