
export enum ModerationRejectReason {
    Urls = 'urls',
    Stopwords = 'stopwords',
    Clone = 'clone',
}

export const ModerationRejectReasonList = [
    {id: ModerationRejectReason.Urls, name: 'Ссылки в описаниях'},
    {id: ModerationRejectReason.Stopwords, name: 'Стопслова'},
    {id: ModerationRejectReason.Clone, name: 'Клон'}
]