export enum LanguageLevel {
    elementary = 'elementary',
    intermediate = 'intermediate',
    advanced = 'advanced',
    proficient = 'proficient',
}
export const LanguageLevelList = [
    {id: LanguageLevel.elementary, name: 'elementary'},
    {id: LanguageLevel.intermediate, name: 'intermediate'},
    {id: LanguageLevel.advanced, name: 'advanced'},
    {id: LanguageLevel.proficient, name: 'proficient'}
]