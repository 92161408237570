
export enum SalaryType {
    perYear = 'perYear',
    perMonth = 'perMonth',
    perHour = 'perHour',
}

export const SalaryTypeList = [
    {id: SalaryType.perYear, name: 'Per year'},
    {id: SalaryType.perMonth, name: 'Per month'},
    {id: SalaryType.perHour, name: 'Per hour'},
]
