import * as React from 'react'
import {
  Pagination,
  DateField,
  Datagrid,
  TextField,
  ReferenceManyField, SelectField,
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import { HirerRoles} from 'src/types/enum/HirerRole'
import ProfileField from 'src/components/fields/ProfileField'

export const ProfileManagerList = (props: any) => {
  const {record} = props
  return (
    <ReferenceManyField {...props} reference="profile" target="ownerId" addLabel={false}
                        sort={{field: 'id', order: 'DESC'}}  pagination={<Pagination/>} perPage={50}>
      <Datagrid>
        <IdShowButtonField type={'show'} source={'id'} label={'ID'}/>
        <SelectField source={'hirerRole'} label={'Роль'} choices={HirerRoles}/>
        <ProfileField source={'lastName'} format={'userName'} label={'ФИО'}/>
        <TextField source={'telegramNickname'} label={'Ник ТГ'}/>
        <TextField source={'country.name'} label={'Страна'}/>
        <TextField source={'city.name'} label={'Город'}/>
        <DateField source={'lastActivityAt'} label={'Активность'} showTime/>
        <DateField source={'createdAt'} label={'Регистрация'} showTime={true} />
      </Datagrid>
    </ReferenceManyField>)
}
