
export enum AiCvRequestStatus {
    Created = 'created',
    InProgress = 'inProgress',
    Finished = 'finished',
    Error = 'error',
}
export const AiCvRequestStatusList = [
    {id: AiCvRequestStatus.Created, name: 'Created'},
    {id: AiCvRequestStatus.InProgress, name: 'In Progress'},
    {id: AiCvRequestStatus.Finished, name: 'Finished'},
    {id: AiCvRequestStatus.Error, name: 'Error'}
]
