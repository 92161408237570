import ListIcon from '@mui/icons-material/List'
import VacancyList from 'src/resources/vacancy/VacancyList'
import VacancyEdit from 'src/resources/vacancy/VacancyEdit'
import VacancyShow from 'src/resources/vacancy/VacancyShow'

export default {
  edit: VacancyEdit,
  show: VacancyShow,
  list: VacancyList,
  icon: ListIcon,
}