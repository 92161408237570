interface WithTranslations extends Request{
  translations?: {languageCode: string}[] | any[]
}
export function getTranslation(record: WithTranslations, lang = 'en') {
  if(!record?.translations){
    return null
  }
  const translation = record?.translations?.find(tr => tr.languageCode === lang) || record?.translations?.find(tr => tr.languageCode === 'en')
  if(!translation && record?.translations?.length > 0){
    return record?.translations![0]
  }
  return translation
}
