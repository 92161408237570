import * as React from 'react'
import {memo} from 'react'
import get from 'lodash/get'
import Chip, {ChipProps} from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import {useRecordContext} from 'ra-core'
import { FieldProps, RaRecord} from 'react-admin'

export interface Props extends FieldProps, Omit<ChipProps, 'label'> {
    render?: (record: RaRecord) => any
}

export const CustomChipField = memo((props: Props) => {
    const {
        className,
        classes: classesOverride,
        source,
        emptyText,
        render,
        ...rest
    } = props
    const record = useRecordContext(props)
    const value = render ? render(record as any) : get(record, source ?? '')

    if (value == null && emptyText) {
        return (
            <Typography
                component="span"
                variant="body2"
                className={className}
            >
                {emptyText}
            </Typography>
        )
    }

    return (
        <Chip
            sx={{margin: 1, cursor: 'inherit'}}
            label={value}
        />
    )
})

CustomChipField.displayName = 'CustomChipField'


export default CustomChipField