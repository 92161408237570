import * as React from 'react'
import {
  Pagination,
  DateField,
  TextField,
  ReferenceManyField,
  SelectField,
    Datagrid,
} from 'react-admin'
import { ModalEditButton} from 'src/components/Modal/ModalButton'
import {UserProfileEditForm} from 'src/resources/user/UserProfileForm'
import { ProfileTypeList} from 'src/types/interfaces/IProfile'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'

interface TitleProps {
  record?: any;
}

export const UserProfileList = (props: any) => {
  const {record} = props
  return (
    <ReferenceManyField {...props} reference="profile" target="userId" addLabel={false}
                        sort={{field: 'id', order: 'ASC'}} pagination={<Pagination/>} perPage={50}>
      <Datagrid>
        <IdShowButtonField type={'show'} source={'id'} label={'ID'}/>
        <SelectField source={'profileType'} label={'Активность'} choices={ProfileTypeList}/>
        <TextField source={'firstName'} label={'Имя'}/>
        <TextField source={'lastName'} label={'Фамилия'}/>
        <TextField source={'patronymic'} label={'Отчество'}/>
        <TextField source={'telegramNickname'} label={'Ник ТГ'}/>
        <TextField source={'company'} label={'Компания'}/>
        <TextField source={'country.name'} label={'Страна'}/>
        <TextField source={'city.name'} label={'Город'}/>
        <DateField source={'lastActivityAt'} label={'Активность'} showTime/>
        <DateField source={'createdAt'} label={'Регистрация'} showTime={true} />
        <ModalEditButton modal={<UserProfileEditForm/>}/>
      </Datagrid>
    </ReferenceManyField>)
}