import * as React from 'react'
import {FC} from 'react'
import {
    Show,
    SimpleShowLayout,
    TextField,
    EditProps, TopToolbar, DateField, FunctionField, SelectField,
} from 'react-admin'

import JsonField from 'src/components/fields/JsonField'
import CvLinkField from 'src/components/fields/CvLinkField'
import AssetField from 'src/components/fields/AssetField'
import {AiCvRequestStatusList} from 'src/types/enum/AiCvRequestStatus'
import VacancyLinkField from 'src/components/fields/VacancyLinkField'
import {AiEngineList} from 'src/types/enum/AiEngine'

interface TitleProps {
  record?: any;
}

const Title: FC<TitleProps> = ({record}) =>
 <span>Ai Request #{record?.id} </span>

const Actions= () => {

  return (
      <TopToolbar>
       </TopToolbar>
  )
}
const AiCvEvaluationRequestShow: FC<EditProps> = props => {
  return (<Show {...props} title={<Title/>} actions={<Actions/>}>
    <SimpleShowLayout>
        <SelectField source={'engine'} label={'Provider'} choices={AiEngineList}/>
        <CvLinkField source={'cv'} label={'Cv'}/>
        <VacancyLinkField source={'vacancy'} label={'Vacancy'}/>
        <SelectField source={'status'} label={'Status'} choices={AiCvRequestStatusList}/>
        <AssetField source={'file'} label={'File'}/>
        <DateField source={'startAt'} label={'Started'} showTime/>
        <DateField source={'finishAt'} label={'Finished'} showTime/>
        <FunctionField source={'reqDuration'} label={'Duration'} render={(i: any) => `${(i.reqDuration / 1000).toFixed(2)} сек`}/>
        <DateField source={'createdAt'} label={'Created'} showTime/>
        <TextField source={'error'} label={'Error'}/>
        <JsonField source={'result'} label={'AI Response JSON'}/>

    </SimpleShowLayout>
  </Show>)
}

export default AiCvEvaluationRequestShow
