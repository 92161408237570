import * as React from 'react'
import {FC} from 'react'
import {
  Create,
  EditProps,
} from 'react-admin'
import ProposalForm from 'src/resources/proposal/ProposalForm'

interface TitleProps {
  record?: any;
}

const Title = (props: TitleProps) =>
 <span>Новая Предложение</span>

const ProposalCreate: FC<EditProps> = props => {
  return (
    <Create {...props} redirect="list" title={<Title/>}>
      <ProposalForm/>
    </Create>
  )
}

export default ProposalCreate