import * as React from 'react'
import {FC} from 'react'
import {
  Create,
  EditProps,
} from 'react-admin'
import SkillForm from 'src/resources/skill/SkillForm'

interface TitleProps {
  record?: any;
}

const Title = (props: TitleProps) =>
 <span>Новая Навык</span>

const SkillCreate: FC<EditProps> = props => {
  return (
    <Create {...props} redirect="list" title={<Title/>}>
      <SkillForm/>
    </Create>
  )
}

export default SkillCreate