import * as React from 'react'
import {FC} from 'react'
import {
  Create,
  EditProps,
} from 'react-admin'
import PaymentForm from 'src/resources/payment/PaymentForm'

interface TitleProps {
  record?: any;
}

const Title = (props: TitleProps) =>
 <span>Новая Платеж</span>

const PaymentCreate: FC<EditProps> = props => {
  return (
    <Create {...props} redirect="list"  title={<Title/>}>
      <PaymentForm/>
    </Create>
  )
}

export default PaymentCreate