import React, {useEffect, useState} from 'react'
import { FC, createElement, ReactNode } from 'react'
import { Card, Box, Typography, Divider, Grid } from '@mui/material'
import {request} from 'src/common/providers/dataProvider'
import Formatter from "src/utils/formatters";
interface IDashboardSummary{
    clients_count: number,
    clients_registered_in_prev_week: number,
    clients_registered_in_week: number,
    employee_count: number,
    hirer_count: number,
    vacancies_count: number,
    vacancies_created_in_prev_week: number,
    vacancies_created_in_week: number,
    cv_evaluation_total: number,
    cv_evaluation_created_in_week: number,
    cv_evaluation_created_in_prev_week: number
}
const CardWithIcon = (props: {
    percent?: string
    to?: string
    title?: string
    subtitle?: string | number
    children?: ReactNode
}) => {
    const { title, subtitle, percent, to, children } = props
    return (
        // @ts-ignore
        <Card
            sx={{
                minHeight: 52,
                display: 'flex',
                flexDirection: 'column',
                flex: '1',
                '& a': {
                    textDecoration: 'none',
                    color: 'inherit',
                },
            }}
        >
            <Box
                sx={{
                    overflow: 'inherit',
                    padding: '16px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    '& .icon': {
                        color: (theme) => (theme.palette.mode === 'dark' ? 'inherit' : percent?.includes('+') ? 'green' : '#dc2440'),
                    },
                }}
            >
                <Box width="1em" className="icon">
                    {percent}
                </Box>
                <Box textAlign="right">
                    <Typography color="textSecondary">{title}</Typography>
                    <Typography variant="h5" component="h2">
                        {subtitle ?? ''}
                    </Typography>
                </Box>
            </Box>
            {children && <Divider />}
            {children}
        </Card>
    )
}
const Details = () => {
    const [summary, setSummary] = useState<IDashboardSummary | null>(null)
    const fetchSummary =  async () => {
        const res = await request(
            '/dashboard/summary',
            {

            }
        )
        setSummary(res.json?.length > 0 ? res.json[0] : null)

    }
    useEffect(() => {
        fetchSummary()
    },[])
/*
Общая стата:
Количество b2b клиентов,
количество b2c клиентов,
 сколько токенов потрачено на b2b (всего и AVR),
  сколько токено потрачено на b2c (всего и AVR).
- Зарегистрировано клиентов (# + WoW%)
- Были активными за неделю (# + WoW%)
- Сколько времени провели в сервисе (# + WoW%)
- Создали вакансий (# + WoW%)
- Закрыли вакансий (# + WoW%)
- Создали ссылок на вакансии (# + WoW%)
- Загрузили резюме сами (# + WoW%) + по каждой вакансии
- Назначили своих резюме на вакансии (# + WoW%) + по каждой вакансии
- Отскорено резюме (# + WoW%) + времени сэкономлено за неделю (# + WoW%)
 */

    console.log('Summary', summary)
    if(!summary){
        return null
    }
    return (
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={2} sm={3} md={3}>
                <CardWithIcon title={'B2B users total'} subtitle={summary?.hirer_count} />
            </Grid>
            <Grid item xs={2} sm={3} md={3}>
                <CardWithIcon title={'B2C users total'} subtitle={summary?.employee_count} />
            </Grid>
            <Grid item xs={2} sm={3} md={3}>
                <CardWithIcon title={'Users total'} subtitle={summary?.clients_count} />
            </Grid>
            <Grid item xs={2} sm={3} md={3}>
                <CardWithIcon title={'Users current week'} subtitle={summary?.clients_registered_in_week} percent={Formatter.formatWow(summary.clients_registered_in_week, summary.clients_registered_in_prev_week)}/>
            </Grid>

            <Grid item xs={2} sm={3} md={3}>
                <CardWithIcon title={'Vacancies total'} subtitle={summary?.vacancies_count} />
            </Grid>
            <Grid item xs={2} sm={3} md={3}>
                <CardWithIcon title={'Vacancies current week'} subtitle={summary?.vacancies_created_in_week} percent={Formatter.formatWow(summary?.vacancies_created_in_week, summary?.vacancies_created_in_prev_week)}/>
            </Grid>

            <Grid item xs={2} sm={3} md={3}>
                <CardWithIcon title={'CV scored total'} subtitle={summary?.vacancies_count} />
            </Grid>
            <Grid item xs={2} sm={3} md={3}>
                <CardWithIcon title={'CV scored current week'} subtitle={summary?.cv_evaluation_created_in_week} percent={Formatter.formatWow(summary?.cv_evaluation_created_in_week, summary?.cv_evaluation_created_in_prev_week)}/>
            </Grid>

        </Grid>
    )
}
const Dashboard = () => {

  return (
     <Details/>
  )
}

export default Dashboard
