import * as React from 'react'
import { useEffect, useState } from 'react'
import { ToggleButton, ToggleButtonProps } from '@mui/material'
import { useTranslate } from 'ra-core'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {
useTiptapEditor
} from 'ra-input-rich-text'
import {RichEditorPreviewModal} from 'src/components/inputs/RichTextInput/RichEditorPreviewModal'
export const RichTextInputPreviewButton = (props: Omit<ToggleButtonProps, 'value'>) => {
  const editor = useTiptapEditor()
  const translate = useTranslate()
  const [showMediaModal, setShowMediaModal] = useState(false)
  const [isActive, setIsActive] = useState(false)

  const label = translate('ra.tiptap.blockquote', {
    _: 'Blockquote',
  })

  useEffect(() => {

  }, [editor])
    const formatHtml = (_html : string) => {
      const html = _html
        return html.replaceAll('<p></p>', '<p><br/></p>').replaceAll(/<li><p>(.*?)<\/p><(\/?)(ol|li|ul)>/gi, '<li>$1<$2$3>')
    }
  return (
      <>
      <ToggleButton
          aria-label={label}
          title={label}
          {...props}
          disabled={!editor?.isEditable}
          onClick={() => setShowMediaModal(true)}
          selected={isActive}
          value="preview"
      >
        <VisibilityIcon fontSize="inherit" />

      </ToggleButton>
        {showMediaModal && <RichEditorPreviewModal html={formatHtml(editor.getHTML())} isShown={showMediaModal} onClose={() => setShowMediaModal(false)} />}

      </>
  )
}