
export enum SubscriptionStatus {
  Draft = 'draft',
  Pending = 'pending',
  Active = 'active',
  Canceled = 'canceled',
  Expired = 'expired',
}
export const SubscriptionStatusList = [
  {id: SubscriptionStatus.Draft, name: 'Draft'},
  {id: SubscriptionStatus.Pending, name: 'Pending'},
  {id: SubscriptionStatus.Active, name: 'Active'},
  {id: SubscriptionStatus.Canceled, name: 'Canceled'},
  {id: SubscriptionStatus.Expired, name: 'Expired'},
]
