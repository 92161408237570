import * as React from 'react'
import { useEffect, useState } from 'react'
import { ToggleButton, ToggleButtonProps } from '@mui/material'
import VideoIcon from '@mui/icons-material/Videocam'
import { useTranslate } from 'ra-core'
import {
useTiptapEditor
} from 'ra-input-rich-text'
import ModalMedia from 'src/components/ModalMedia'
import {AssetType, IAsset} from 'src/components/types'
export const RichTextInputVideoButton = (props: Omit<ToggleButtonProps, 'value'>) => {
  const editor = useTiptapEditor()
  const translate = useTranslate()
  const [showMediaModal, setShowMediaModal] = useState(false)
  const [isActive, setIsActive] = useState(false)

  const label = translate('ra.tiptap.blockquote', {
    _: 'Blockquote',
  })

  useEffect(() => {
    const handleUpdate = () => {
      setIsActive(editor && editor.isActive('blockquote'))
    }

    if (editor) {
      editor.on('update', handleUpdate)
      editor.on('selectionUpdate', handleUpdate)
    }

    return () => {
      if (editor) {
        editor.off('update', handleUpdate)
        editor.off('selectionUpdate', handleUpdate)
      }
    }
  }, [editor])
  const handleSelectAsset = (record: IAsset) => {
    console.log('handleSelect', record)
    setShowMediaModal(false)
    const imagePath = `/api/asset/uploads/${record.source}`
    editor.chain().focus().setVideo( imagePath).run()
  }
  console.log('showMediaModal', showMediaModal)
  return (
      <>
      <ToggleButton
          aria-label={label}
          title={label}
          {...props}
          disabled={!editor?.isEditable}
          onClick={() => setShowMediaModal(true)}
          selected={isActive}
          value="quote"
      >
        <VideoIcon fontSize="inherit" />

      </ToggleButton>
        {showMediaModal && <ModalMedia filter={{type: AssetType.VIDEO}} isShown={showMediaModal} onClose={() => setShowMediaModal(false)} onSelect={handleSelectAsset}/>}

      </>
  )
}