import * as React from 'react'
import {
  SimpleForm, TextInput, required,
} from 'react-admin'

const KeywordForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <TextInput source={'title'} label={'Название'} variant={'outlined'} validate={required()}/>

    </SimpleForm>
  )
}
export default KeywordForm
