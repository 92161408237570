import * as React from 'react'
import {
  TabbedForm,
  FormTab,
  TextInput, SelectInput, required,
} from 'react-admin'
import {useFormContext} from 'react-hook-form'
import {AdminRoles} from 'src/types/enum/AdminRole'
const InfoTab = (props: any) => {
  const form = useFormContext()
  return (<FormTab
    label="Информация"
    path={'benefit'}
    {...props}>

    <SelectInput
        source="role"
        label={'Роль'}
        choices={AdminRoles}
        fullWidth
        variant={'outlined'}
        validate={required()}
    />
    <TextInput
        source="email"
        label={'Email'}
        fullWidth
        variant={'outlined'}
        validate={required()}
    />
    <TextInput
        source="name"
        label={'Имя'}
        fullWidth
        variant={'outlined'}
    />

    <TextInput
        source="setPassword"
        label={'Пароль'}
        variant={'outlined'}
        fullWidth
    />
    </FormTab>)
}
const AdminForm = (props: any) => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab/>
    </TabbedForm>
  )
}
export default AdminForm
