import * as React from 'react'
import {FC} from 'react'
import {
  Show,
  TabbedShowLayout,
  DateField,
  Tab,
  EditProps,
  TextField,
} from 'react-admin'
import {UserProfileList} from 'src/resources/user/UserProfileList'

interface TitleProps {
  record?: any;
}

const Title: FC<TitleProps> = ({record}) =>
 <span>Пользователь #{record?.id} {record?.email ?? ''} {record?.phone ?? ''}</span>

const UserShow: FC<EditProps> = props => {
  return (<Show {...props} title={<Title/>}>
    <TabbedShowLayout>
      <Tab label="Информация">
        <TextField source={'id'} label={'ID'}/>
        <TextField source={'phone'} label={'Телефон'}/>
        <TextField source={'email'} label={'Email'}/>
        <TextField source={'balance'} label={'Баланс'}/>
        <DateField source={'createdAt'} label={'Регистрация'} showTime/>
        <DateField source={'updatedAt'} label={'Обновлен'} showTime/>
      </Tab>
      <Tab label="Профили" path="profiles">
        <UserProfileList />
      </Tab>

    </TabbedShowLayout>
  </Show>)
}

export default UserShow