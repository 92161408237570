import * as React from 'react'
import {FC} from 'react'
import {
  EditProps,
} from 'react-admin'
import ApplicationForm from 'src/resources/application/ApplicationForm'
import {CustomEdit} from 'src/components/CustomEdit'

interface TitleProps {
  record?: any;
}

const ApplicationEdit: FC<EditProps> = props => {
  return (
    <CustomEdit {...props} redirect="list" mutationMode="pessimistic">
    <ApplicationForm/>
    </CustomEdit>
  )
}
export default ApplicationEdit
