import ListIcon from '@mui/icons-material/List'
import AiVacancyGenRequestList from 'src/resources/ai-vacancy-gen-request/AiVacancyGenRequestList'
import AiVacancyGenRequestEdit from 'src/resources/ai-vacancy-gen-request/AiVacancyGenRequestEdit'
import AiVacancyGenRequestCreate from 'src/resources/ai-vacancy-gen-request/AiVacancyGenRequestCreate'
import AiVacancyGenRequestShow from 'src/resources/ai-vacancy-gen-request/AiVacancyGenRequestShow'

export default {
  create: AiVacancyGenRequestCreate,
  edit: AiVacancyGenRequestEdit,
  list: AiVacancyGenRequestList,
  show: AiVacancyGenRequestShow,
  icon: ListIcon,
}
