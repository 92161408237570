import * as React from 'react'
import {FC} from 'react'
import {
  Create,
  EditProps,
} from 'react-admin'
import ProfileForm from 'src/resources/profile/ProfileForm'

interface TitleProps {
  record?: any;
}

const Title = (props: TitleProps) =>
 <span>Новая Профиль</span>

const ProfileCreate: FC<EditProps> = props => {
  return (
    <Create {...props}  title={<Title/>}>
      <ProfileForm/>
    </Create>
  )
}

export default ProfileCreate