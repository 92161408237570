import * as React from 'react'
import {
  Pagination,
  DateField,
  TextField,
  Datagrid,
  ReferenceManyField, SelectField,
} from 'react-admin'
import ProfileLinkField from 'src/components/fields/ProfileLinkField'
import {ProposalStatusList} from 'src/types/enum/ProposalStatus'
import VacancyLinkField from 'src/components/fields/VacancyLinkField'

interface TitleProps {
  record?: any;
}

export const CvProposalList = (props: any) => {
  const {record} = props
  return (
    <ReferenceManyField {...props} reference="proposal" target="cvId" addLabel={false}
                        sort={{field: 'id', order: 'ASC'}} pagination={<Pagination/>} perPage={50}>
      <Datagrid>
        <TextField source={'id'} label={'ID'}/>
        <SelectField source={'status'} label={'Статус'} choices={ProposalStatusList}/>
        <VacancyLinkField source={'vacancy'} label={'Вакансия'}/>
        <ProfileLinkField label={'Профиль компании'} source={'profile'}/>
        <DateField source={'createdAt'} label={'Создан'} showTime/>
      </Datagrid>
    </ReferenceManyField>)
}