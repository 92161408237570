import * as React from 'react'
import {useRecordContext} from 'ra-core'
import { FieldProps, RaRecord} from 'react-admin'
import get from 'lodash/get'
type Callback = (record: RaRecord) => string
interface Props<RecordType extends RaRecord = any> extends FieldProps {

}

const EmailField = (props: Props) => {
    const record = useRecordContext(props)
    const value = get(record, props.source ?? '')
    return (
        <a
            href={`mailto:${value}`}
        >
            {value}
        </a>
    )
}


export default EmailField
