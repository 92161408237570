import * as React from 'react'
import {
  Pagination,
  DateField,
  Datagrid,
  TextField,
  ReferenceManyField,
  SelectField,
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import VisibilityField from 'src/components/fields/VisibilityField'
import {ModerationStatusList} from 'src/types/enum/ModerationStatus'

interface TitleProps {
  record?: any;
}

export const UserVacancyList = (props: any) => {
  const {record} = props
  return (
    <ReferenceManyField {...props} reference="vacancy" target="profileId" addLabel={false}
                        sort={{field: 'id', order: 'DESC'}} pagination={<Pagination/>} perPage={50}>
      <Datagrid bulkActionButtons={false}>
        <IdShowButtonField type={'show'} source={'id'} label={'ID'}/>
        <VisibilityField source={'published'} label={'П'}/>
        <TextField source={'name'} label={'Имя'}/>
        <SelectField source={'moderationStatus'} label={'Статус'} choices={ModerationStatusList}/>
        <TextField source={'contactName'} label={'Контакты'}/>
        <TextField source={'country.name'} label={'Страна'}/>
        <TextField source={'city.name'} label={'Город'}/>
        <TextField source={'phone'} label={'Телефон'}/>
        <TextField source={'email'} label={'Email'}/>
        <DateField source={'createdAt'} label={'Создано'} showTime={true} />
      </Datagrid>
    </ReferenceManyField>)
}
