import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { CreateButton } from 'react-admin'

const EmptyList = ({ basePath, title = 'Список пуст', hasCreate, description, buttonText }: any) => (
  <Box textAlign="center" m={3} flex={1}>
    <Typography variant="h4" paragraph>
      {title}
    </Typography>
    <Typography variant="body1">
      {description}
    </Typography>
      {hasCreate && <CreateButton label={buttonText} resource={basePath} />}
  </Box>
)
EmptyList.defaultProps = {
    hasCreate: true
}
export default EmptyList
