import * as React from 'react'
import {FC} from 'react'
import {
  EditProps,
} from 'react-admin'
import AiCvRequestForm from 'src/resources/ai-cv-request/AiCvRequestForm'
import {CustomEdit} from 'src/components/CustomEdit'

interface TitleProps {
  record?: any;
}
const Title: FC<TitleProps> = ({record}) =>
  record ? <span>AI распознование резюме {record.name}</span> : null

const AiCvRequestEdit: FC<EditProps> = props => {
  return (
    <CustomEdit {...props} redirect="list" mutationMode="pessimistic" title={<Title/>}>
    <AiCvRequestForm/>
    </CustomEdit>
  )
}
export default AiCvRequestEdit