import * as React from 'react'
import {FC} from 'react'
import {
  Show,
  TabbedShowLayout,
  DateField,
  EditButton,
  Tab,
    Datagrid,
  EditProps,
  TextField, SelectField, ArrayField, SingleFieldList, TopToolbar,
} from 'react-admin'
import {CvProposalList} from 'src/resources/cv/CvProposalList'
import {GenderList} from 'src/types/interfaces/IProfile'

import CustomChipField from 'src/components/fields/CustomChipField'
import VisibilityField from 'src/components/fields/VisibilityField'
import { VacancyLookTypeList} from 'src/types/enum/VacancyLookType'
import {ModerationStatusList} from 'src/types/enum/ModerationStatus'
import {ModerationRejectReasonList} from 'src/types/enum/ModerationRejectReason'
import {WorkExperiencePresenseList} from 'src/types/enum/WorkExperiencePresense'
import {EducationList} from 'src/types/enum/Education'
import {LanguageLevelList} from 'src/types/enum/LanguageLevel'
import ProfileLinkField from 'src/components/fields/ProfileLinkField'
import {ModalButton} from 'src/components/Modal/ModalButton'
import {CvStatusModal} from 'src/resources/cv/CvStatusModal'
import { SalaryTypeList} from 'src/types/enum/SalaryType'
import {RelocationList} from 'src/types/enum/Relocation'

interface TitleProps {
  record?: any;
}

const Title: FC<TitleProps> = ({record}) =>
 <span>Резюме #{record?.id} </span>

const Actions= () => {

  return (
      <TopToolbar>
        <EditButton/>
        <ModalButton label={'Модерация'} modal={<CvStatusModal/>}/>
      </TopToolbar>
  )
}
const CvShow: FC<EditProps> = props => {
  return (<Show {...props} title={<Title/>} actions={<Actions/>}>
    <TabbedShowLayout>
      <Tab label="Информация">
        <TextField source={'id'} label={'ID'}/>
        <VisibilityField source={'published'} label={'Published'}/>
        <SelectField source={'moderationStatus'} label={'Статус модерации'} choices={ModerationStatusList}/>
        <SelectField source={'moderationRejectReason'} label={'Причины отказа модерации'} choices={ModerationRejectReasonList}/>
        <ProfileLinkField label={'Profile'} source={'profile'}/>
        <ProfileLinkField label={'Profile owner'} source={'owner'}/>
        <TextField source={'title'} label={'Title'}/>
        <TextField source={'firstName'} label={'firstName'}/>
        <TextField source={'lastName'} label={'lastName'}/>
        <TextField source={'patronymic'} label={'patronymic'}/>
        <TextField source={'birthday'} label={'Birthday'}/>
        <TextField source={'age'} label={'Age'}/>
        <SelectField source={'gender'} label={'Gneder'} choices={GenderList}/>
        <TextField source={'about.description'} label={'About'}/>

        <SelectField source={'relocation'} label={'Relocation'} choices={RelocationList}/>
        <SelectField source={'workExperiencePresense'} label={'Expirience Presense'} choices={WorkExperiencePresenseList}/>
        <SelectField source={'education'} label={'Education'} choices={EducationList}/>
       <TextField source={'position'} label={'Position'}/>
        <TextField source={'portfolio'} label={'Портфолио'}/>
        <TextField source={'category.name'} label={'Category'}/>
        <TextField source={'subCategory.name'} label={'Sub-category'}/>
        <ArrayField source="skills"  label={'Навыки'}>
          <SingleFieldList>
            <CustomChipField render={(i: any) => `${i.title}`}/>
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="benefits"  label={'Источники'}>
          <SingleFieldList>
            <CustomChipField render={(i: any) => i.title}/>
          </SingleFieldList>
        </ArrayField>
        <TextField source={'salaryMin'} label={'Salary min'}/>
        <TextField source={'salaryMax'} label={'Salary max'}/>
        <SelectField source={'salaryType'} label={'Salary type'} choices={SalaryTypeList}/>

        <TextField source={'commission'} label={'Процент'}/>
        <SelectField source={'vacancyLookType'} label={'Состояние резюме'} choices={VacancyLookTypeList}/>
        <TextField source={'currency'} label={'Валюта'}/>


        <TextField source={'nativeLanguage'} label={'Основной язык'}/>
        <ArrayField source="languageKnowledges"  label={'Языки'}>
          <SingleFieldList>
            <CustomChipField render={(i: any) => `${i.language} - ${LanguageLevelList.find((a: any) => a.id === i.level)?.name ?? ''}`}/>
          </SingleFieldList>
        </ArrayField>

        <DateField source={'createdAt'} label={'Создано'} showTime={true} />
        <TextField source={'contacts.email'} label={'Email'}/>
        <TextField source={'contacts.phone'} label={'Phone'}/>
          <ArrayField source="experienceInfo"  label={'Expirience'}>
              <Datagrid bulkActionButtons={false}>
                  <TextField source={'company'} label={'Company'}/>
                  <TextField source={'position'} label={'Position'}/>
                  <TextField source={'description'} label={'Description'}/>
                  <DateField source={'fromDate'} label={'From'}/>
                  <DateField source={'toDate'} label={'To'}/>
              </Datagrid>
          </ArrayField>

          <ArrayField source="educationInfo"  label={'Education'}>
              <Datagrid bulkActionButtons={false}>
                  <TextField source={'institution'} label={'Institution'}/>
                  <TextField source={'speciality'} label={'Speciality'}/>
                  <DateField source={'fromDate'} label={'From'}/>
                  <DateField source={'toDate'} label={'To'}/>
              </Datagrid>
          </ArrayField>
        <TextField source={'country.name'} label={'Phone'}/>
        <TextField source={'city.name'} label={'Город'}/>
         </Tab>
      <Tab label="Офферы" path="proposal">
        <CvProposalList />
      </Tab>
    </TabbedShowLayout>
  </Show>)
}

export default CvShow
