import * as React from 'react'

import { AppBar, AppBarProps} from 'react-admin'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
export const MyAppBar = (props: AppBarProps) => (
    <AppBar
        elevation={1}
        sx={{
            '& .RaAppBar-title': {
                flex: 1,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
            },
        }}
        {...props}
    >
        <Box flex="1">
            <Typography variant="h6" id="react-admin-title"></Typography>
        </Box>

    </AppBar>
)
