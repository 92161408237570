import React, {cloneElement, ReactElement} from 'react'
import Typography from '@mui/material/Typography'
import {Datagrid, ListToolbar, RaRecord, TopToolbar, useListContext, DatagridProps} from 'react-admin'
import RelatedEmptyList from 'src/components/list/RelatedList/RelatedEmptyList'
import {Card} from '@mui/material'
import styles from './index.module.scss'
interface Props extends DatagridProps{
    title?: string
    record?: RaRecord
    actions?: ReactElement[] | ReactElement | false
    hasCreate?: boolean
    emptyTitle?: string
    emptyButtonText?: string
    createButtonLabel?: string
    emptyDescription?: string
    createButton?:ReactElement
    createButtonTo?: (record?: RaRecord) => void | string
    filters?: ReactElement
}

const RelatedList = (props: Props) => {

    const list = useListContext()
    console.log('RelatedListProps', list)

    const emptyView = props.empty ? cloneElement(props.empty, props) : <RelatedEmptyList {...props} hasCreate={props.hasCreate}   title={props.emptyTitle} desription={props.emptyDescription} buttonText={props.emptyButtonText ?? props.createButtonLabel} to={typeof props.createButtonTo === 'function' ?  props.createButtonTo(props.record) : props.createButtonTo} />

    return (
        <>
            {props.title && (<Typography variant="h6" component="h6">
                {props.title}
            </Typography>)}
            {(list.total > 0 || props.filters) && <ListToolbar  filters={props.filters}  actions={<TopToolbar>{props.actions}</TopToolbar>} hasCreate={props.hasCreate} />}
            <Card className={styles.card}>
                <Datagrid total={list.total} data={list.data} isLoading={list.isLoading} {...props} empty={emptyView} />
            </Card>
        </>
    )}
export default RelatedList
