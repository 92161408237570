import * as materialColors from '@mui/material/colors'
import {defaultTheme} from 'react-admin'

const colors = {
    primary: '#24B563',
    textPrimary: '#3C3C3C',
    textSecondary: '#7C7C8C',
    textDark: '#16161B',
    bg: '#EBEBEB',
    bgSecondary: '#fff',
    secondary: materialColors.blue[700],
}


export const darkTheme = {

    ...defaultTheme,

    palette: {
        primary: {
            main: colors.primary,
            dark: colors.primary
        },
        secondary: {
            main: colors.primary,
            dark: colors.primary
        },
        text: {
            primaryText: colors.textPrimary,
            secondary: colors.textSecondary,
        },
        type: 'dark' // Switching the dark mode on is a single property value change.
    },
    overrides: {
        sidebar: {
            width: 340,
            closedWidth: 55,
        },
        RaSidebar: {
            fixed: {
                zIndex: 1200,
            },
        },
    },

}

export const lightTheme = {
    ...darkTheme,
    palette: {
        ...darkTheme.palette,
        type: 'light' as 'light',
    }
}
