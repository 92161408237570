import ListIcon from '@mui/icons-material/List'
import CvList from 'src/resources/cv/CvList'
import CvEdit from 'src/resources/cv/CvEdit'
import CvShow from 'src/resources/cv/CvShow'

export default {
  show: CvShow,
  edit: CvEdit,
  list: CvList,
  icon: ListIcon,
}