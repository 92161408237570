
export enum SubscriptionTimeUnit {
  Month = 'month',
  Week = 'week',
  Day = 'day',
  Hour = 'hour',
}
export const SubscriptionTimeUnitList = [
  {id: SubscriptionTimeUnit.Month, name: 'Month'},
  {id: SubscriptionTimeUnit.Week, name: 'Week'},
  {id: SubscriptionTimeUnit.Day, name: 'Day'},
  {id: SubscriptionTimeUnit.Hour, name: 'Hour'},
]
