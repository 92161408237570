import * as React from 'react'
import {BreadcrumbProps,     Breadcrumb as DefaultBreadcrumb,} from '@glm/ra-navigation'
import {useTheme} from 'react-admin'
import {ResourceBreadcrumbItems} from 'src/components/layout/ResourceBreadcrumb'

export const Breadcrumb = (props: BreadcrumbProps) => {
    const theme = useTheme()
    console.log('Theme111', theme)
    return (
        <DefaultBreadcrumb sx={{ marginTop: 1 }} {...props}>
            <ResourceBreadcrumbItems />
        </DefaultBreadcrumb>
    )
}