
export enum Education {
    secondary = 'secondary',
    secondarySpecial = 'secondarySpecial',
    incompleteHigher = 'incompleteHigher',
    higher = 'higher',
    bachelor = 'bachelor',
    master = 'master',
    PhD = 'PhD',
    doctor = 'doctor',
}
export const EducationList = [
    {id: Education.secondary, name: 'Среднее'},
    {id: Education.secondarySpecial, name: 'Среднеспенциальное'},
    {id: Education.incompleteHigher, name: 'Неоконченное высше'},
    {id: Education.higher, name: 'Высшее'},
    {id: Education.bachelor, name: 'Бакалавр'},
    {id: Education.master, name: 'Магистр'},
    {id: Education.PhD, name: 'Кандидат Наук'},
    {id: Education.doctor, name: 'Доктор Наук'},
]