import * as React from 'react'
import {FC} from 'react'
import {
  Create,
  EditProps,
} from 'react-admin'
import BenefitForm from 'src/resources/benefit/BenefitForm'

interface TitleProps {
  record?: any;
}

const Title = (props: TitleProps) =>
 <span>Новая Benefit</span>

const BenefitCreate: FC<EditProps> = props => {
  return (
    <Create {...props} redirect="list"  title={<Title/>}>
      <BenefitForm/>
    </Create>
  )
}

export default BenefitCreate