import * as React from 'react'
import {FC, useRef} from 'react'
import {
    required,
    NumberInput, SelectInput, ArrayInput, SimpleFormIterator, BooleanInput, FormDataConsumer, DateTimeInput
} from 'react-admin'
import Box from '@mui/material/Box'
import {IFocalPoint} from 'src/components/types'
import {SubscriptionKindList} from 'src/types/enum/SubscriptionKind'
import {SubscriptionStatusList} from 'src/types/enum/SubscriptionStatus'
import { SubscriptionTimeUnitList} from 'src/types/enum/SubscriptionTimeUnit'
import SubscriptionTimeUnitPeriodInput from 'src/components/inputs/SubscriptionTimeUnitPeriodInput'
type UpdateCallback = (value: IFocalPoint | null) => void
interface ProductTitleProps {
    record?: any;
}
export const SubscriptionEditForm: FC<any> = props => {
    const {record} = props
    const currentEditRef = useRef()
    return (
        <Box >
            <SelectInput source="status" label={'Status'} variant={'outlined'} choices={SubscriptionStatusList} validate={required()} fullWidth={true}/>
            <SelectInput disabled={!!record} source="kind" label={'Kind'} variant={'outlined'} choices={SubscriptionKindList} validate={required()} fullWidth={true}/>
            <SelectInput source="timeUnit" label={'Time Unit'}    variant={'outlined'} fullWidth={true} choices={SubscriptionTimeUnitList}  />
            <DateTimeInput source={'startAt'} label={'Старт подписки'} variant={'outlined'} fullWidth={true}/>
            <FormDataConsumer fullWidth={true} variant={'outlined'}>
                {({ formData }) =>
                    (<SubscriptionTimeUnitPeriodInput source="period" label={'Period'}  timeUnit={formData.timeUnit} variant={'outlined'} fullWidth={true} />)
                    }
            </FormDataConsumer>
            <NumberInput source="price" label={'Price'}   variant={'outlined'} fullWidth={true}  />
            <ArrayInput source="priceByPeriod" label={'Prices by periods'} >
                <SimpleFormIterator fullWidth disableReordering={true} inline >
                    <FormDataConsumer fullWidth={true} variant={'outlined'}>
                        {({ formData }) =>
                            (<SubscriptionTimeUnitPeriodInput source="period" label={'Period'}  timeUnit={formData.timeUnit} variant={'outlined'} fullWidth={true} />)
                        }
                    </FormDataConsumer>
                    <NumberInput source="price" label={'Price'} helperText={false}  variant={'outlined'} fullWidth={false}  />
                </SimpleFormIterator>
            </ArrayInput>
            <BooleanInput source="managersUnlimited" label={'Managers Unlimited'} variant={'outlined'} fullWidth  />
            <FormDataConsumer fullWidth={true} variant={'outlined'}>
                {({ formData, ...rest }) => !formData.managersUnlimited  && (
                    <NumberInput source="managersLimit" label={'Managers Limit'} variant={'outlined'} fullWidth  />
                )}
            </FormDataConsumer>

            <BooleanInput source="jobsUnlimited" label={'Jobs Unlimited'} variant={'outlined'} fullWidth  />
            <FormDataConsumer fullWidth={true} variant={'outlined'}>
                {({ formData, ...rest }) => !formData.jobsUnlimited && (
                    <NumberInput source="jobsLimit" label={'Jobs Limit'} variant={'outlined'} fullWidth  />
                )}
            </FormDataConsumer>

            <BooleanInput source="evaluationUnlimited" label={'Evaluations Unlimited'} variant={'outlined'} fullWidth  />
            <FormDataConsumer fullWidth={true} variant={'outlined'}>
                {({ formData, ...rest }) => !formData.evaluationUnlimited  && (
                    <NumberInput source="evaluationLimit" label={'Evaluations Limit'} variant={'outlined'} fullWidth  />
                )}
            </FormDataConsumer>
        </Box>

    )
}
const requiredValidate = [required()]


