import * as React from 'react'
import { FieldProps, RaRecord, FunctionField} from 'react-admin'
import Formatter from 'src/utils/formatters'
interface Props<RecordType extends RaRecord = any> extends FieldProps {
   format?: 'companyName' | 'userName'
}

const ProfileField = (props: Props) => {
   const format = props.format ?? 'companyName'
   return  <FunctionField {...props} source={props.source ?? 'profile.name'} render={(record: any) => format === 'companyName' ? Formatter.formatProfileName(props.source === 'lastName' ? record : record[props.source ?? 'profile']) : Formatter.formatProfileUserName(props.source === 'lastName' ? record : record[props.source ?? 'profile'])}  label={props.label}/>
}

export default ProfileField
