import * as React from 'react'
import {
  FormTab,
  required,
  SelectInput,
  TabbedForm,
  TextInput, ArrayInput, SimpleFormIterator, ReferenceInput, AutocompleteInput,
} from 'react-admin'
import {getTranslation} from 'src/utils/translation'
const Form = (props: any) => {
  return (
      <FormTab
        {...props}
        label="Категория"
      >
        {props.isNew && <ReferenceInput
            source="parentId"
            reference="service-category"
            sort={{ field: 'translations.name', order: 'ASC' }}
            filter={{'translations.languageCode': 'en'}}
            perPage={60}
        >
          <AutocompleteInput noOptionsText="-"  filterToQuery={(q: string) => ({'translations.name:$contL': q})} optionText={(i) => getTranslation(i)?.name} label={'Родительская категория'} fullWidth variant={'outlined'}/>

        </ReferenceInput>}
        <ArrayInput source="translations" label={'Контент'}>
          <SimpleFormIterator fullWidth>
            <SelectInput source="languageCode" label={'Язык'} variant={'outlined'} choices={[{id: 'en', name: 'EN'}, {id: 'id', name: 'Bahasa'}]} validate={required()} />
            <TextInput source="name" label={'Название'} variant={'outlined'} fullWidth validate={required()} />
            <TextInput source="description" label={'Описание'} multiline variant={'outlined'} fullWidth  />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
  )
}

export const ServiceCategoryForm = (props: any) => {
  return (
    <TabbedForm {...props} redirect={'list'}>
     <Form isNew={props.isNew}/>

    </TabbedForm>
  )
}

const requiredValidate = [required()]
