import * as React from 'react'
import {
  Pagination,
  DateField,
  TextField,
  Datagrid,
  ReferenceManyField, SelectField,
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {ModerationStatusList} from 'src/types/enum/ModerationStatus'
import VisibilityField from 'src/components/fields/VisibilityField'
import ProfileLinkField from 'src/components/fields/ProfileLinkField'

interface TitleProps {
  record?: any;
}

export const UserCvList = (props: any) => {
  const {record} = props
  return (
    <ReferenceManyField {...props} reference="cv" target="profileId" addLabel={false}
                        sort={{field: 'id', order: 'ASC'}} pagination={<Pagination/>} perPage={50}>
      <Datagrid bulkActionButtons={false} >
        <IdShowButtonField type={'show'} source={'id'} label={'ID'}/>
        <SelectField source={'moderationStatus'} label={'Статус модерации'} choices={ModerationStatusList}/>
        <VisibilityField source={'published'} label={'Опубликован'}/>
        <ProfileLinkField label={'Профиль'} source={'profile'}/>
        <TextField source={'firstName'} label={'Имя'}/>
        <TextField source={'lastName'} label={'Фамилия'}/>
        <TextField source={'patronymic'} label={'Отчество'}/>
        <TextField source={'birthday'} label={'ДР'}/>
        <TextField source={'gender'} label={'Пол'}/>
        <TextField source={'salary'} label={'Зарплата'}/>
        <DateField source={'createdAt'} label={'Создано'} showTime={true} />
      </Datagrid>
    </ReferenceManyField>)
}
