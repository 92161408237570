
export enum ModerationStatus {
    Moderation = 'moderation',
    Rejected = 'rejected',
    Approved = 'approved',
    ManualModeration = 'manualModeration',
}

export const ModerationStatusList = [
    {id: ModerationStatus.Moderation, name: 'Модерация'},
    {id: ModerationStatus.Rejected, name: 'Отклонено'},
    {id: ModerationStatus.Approved, name: 'Одобрено'},
    {id: ModerationStatus.ManualModeration, name: 'Ручная модерация'},
]