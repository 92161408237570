import * as React from 'react'
import { FieldProps, RaRecord} from 'react-admin'
import LinkField from 'src/components/fields/LinkField'
import Formatter from 'src/utils/formatters'
interface Props<RecordType extends RaRecord = any> extends FieldProps {
   format?: 'companyName' | 'userName'
}

const ProfileLinkField = (props: Props) => {
   const format = props.format ?? 'companyName'
   return  <LinkField link={(record) => `/profile/${record[props.source ?? 'profile']?.id}`} source={props.source ?? 'profile.name'} value={(record) => format === 'companyName' ? Formatter.formatProfileName(props.source === 'lastName' ? record : record[props.source ?? 'profile']) : Formatter.formatProfileUserName(props.source === 'lastName' ? record : record[props.source ?? 'profile'])}  label={props.label}/>
}

export default ProfileLinkField
