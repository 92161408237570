import * as React from 'react'

import {Button, useDataProvider} from 'react-admin'
import {Identifier} from 'ra-core'
import MenuItem from '@mui/material/MenuItem'

export interface Props {
    buttonView: 'button' | 'menuItem'
    profileId: Identifier
}

const ImpersonateButton = (props: Props) => {
    const dataProvider = useDataProvider()

    const handleClick = async () => {
        const res = await dataProvider.create('profile-impersonate-token', {data: {profileId: props.profileId}})
        console.log('res', res)
        if (res.data.link) {
            window.open(res.data.link, '_blank')

        }
    }

    switch (props.buttonView) {
        case 'button':
            return <Button type={'button'} size={'small'} onClick={handleClick} label={'Войти'}/>
        case 'menuItem':
            return (<MenuItem onClick={handleClick}>
                Войти
            </MenuItem>)
    }
}
export default ImpersonateButton
