import * as React from 'react'
import {Link} from 'react-router-dom'
import {useRecordContext} from 'ra-core'
import { FieldProps, RaRecord} from 'react-admin'
import get from 'lodash/get'
type Callback = (record: RaRecord) => string
interface Props<RecordType extends RaRecord = any> extends FieldProps {
    link: string | Callback
    value?: string | Callback
}

const LinkField = (props: Props) => {
    const record = useRecordContext(props)
    const link = (typeof props.link === 'function' ? props.link(record as any) : props.link)?.replaceAll('$id', record['id'] as string)
    const value = (props.value ? typeof props.value === 'function' ? props.value(record as any) : props.value : get(record, props.source ?? ''))
    if(!value){
        return null
    }
    return (
        <Link
            to={encodeURI(link)}
        >
            {value}
        </Link>
    )
}


export default LinkField
