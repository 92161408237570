
export enum ApplicationStatus {
    Applied = 'applied',
    Accepted = 'accepted',
    Rejected = 'rejected',
}
export const ApplicationStatusList = [
    {id: ApplicationStatus.Applied, name: 'Создан'},
    {id: ApplicationStatus.Accepted, name: 'Одобрен'},
    {id: ApplicationStatus.Rejected, name: 'Отклонен'}
]