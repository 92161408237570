import * as React from 'react'
import {FC} from 'react'
import {
    Filter,
    List,
    ListProps,
    Datagrid, TextField,
    FunctionField,
} from 'react-admin'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import Formatter from "src/utils/formatters";

const exporter = (posts: any[]) => {
    return csvExporter({
        columns: {
            'createdAt': 'Создано',
        },
        data: posts.map((item: any) => ({
          ...item,

            createdAt: formatCsvDate(item.createdAt),
            updatedAt: formatCsvDate(item.updatedAt)
        })),
        fileName: 'Company'
    })
}

const _Filter = (props: any) => {
    return (
        <Filter {...props}>

        </Filter>
    )
}

const DashboardEmployee: FC<ListProps> = props => {
    return (
        <List
            {...props}
            exporter={exporter}
            filters={<_Filter/>}
            title="Companies"

            sort={{field: 'id', order: 'DESC'}}
            empty={<EmptyList title={'No Data'} hasCreate={false}/>}
        >
            <Datagrid bulkActionButtons={false}>
                <TextField source={'email'} label={'Email'}/>
                <TextField source={'total_cvs'} label={'Total CV'}/>
                <TextField source={'cvs_created_in_week'} label={'Total CV in week'}/>
                <TextField source={'cvs_created_in_prev_week'} label={'Vacancies'}/>
                <TextField source={'total_views'} label={'Total views'}/>
                <FunctionField source={'views_in_week'} label={'Views in week'} render={(i: any) => `${i.views_in_week} ${Formatter.formatWow(i.views_in_week, i.views_prev_week)}`}/>

                <TextField source={'total_applications'} label={'Total Applications'}/>
                <FunctionField source={'views_in_week'} label={'Applications in week'} render={(i: any) => `${i.applications_created_in_week} ${Formatter.formatWow(i.applications_created_in_week, i.applications_created_in_prev_week)}`}/>
                <TextField source={'total_proposals'} label={'Total proposals'}/>
                <FunctionField source={'views_in_week'} label={'Proposals in week'} render={(i: any) => `${i.proposals_created_in_week} ${Formatter.formatWow(i.proposals_created_in_week, i.proposals_created_in_prev_week)}`}/>
            </Datagrid>
        </List>
    )
}

export default DashboardEmployee
