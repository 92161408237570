import * as React from 'react'
import {FC} from 'react'
import {
    Filter,
    List,
    SelectField,
    ListProps,
    Datagrid, TextField, DateField, SelectInput,
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import {ModerationStatusList} from 'src/types/enum/ModerationStatus'
import ProfileLinkField from 'src/components/fields/ProfileLinkField'
import ProfileInput from 'src/components/inputs/ProfileInput'
import DateRangeFilterInput from 'src/components/inputs/DateRangeFilterInput'
import CategoriesArrayInput from 'src/components/inputs/CategoriesArrayInput'

import {DropdownMenu} from 'src/components/DropdownMenu/DropdownMenu'
import {MenuModalButton} from 'src/components/Modal/MenuModalButton'
import {CvStatusModal} from 'src/resources/cv/CvStatusModal'

const ActionFields = () => {
    return (<DropdownMenu>
        <MenuModalButton label={'Модерация'} type={'iconButton'} key={'import'}
                         modal={<CvStatusModal refresh/>}>Модерация</MenuModalButton>
    </DropdownMenu>)
}
const exporter = (posts: any[]) => {
    return csvExporter({
        columns: {
            'createdAt': 'Создано',
        },
        data: posts.map((item: any) => ({
            ...item,

            createdAt: formatCsvDate(item.createdAt),
            updatedAt: formatCsvDate(item.updatedAt)
        })),
        fileName: 'Cv'
    })
}

const _Filter = (props: any) => {
    return (
        <Filter {...props}>
            <ProfileInput source={'profileId'} label={'Профиль'} alwaysOn resettable/>
            <SelectInput source={'status'} label={'Статус модерации'} choices={ModerationStatusList} fullWidth
                         variant={'outlined'}/>
            <CategoriesArrayInput source={'categories.id:$in'} label={'Категории'}/>
            <DateRangeFilterInput source={'createdAt:$between'} label="Создано"
                                  alwaysOn/>
        </Filter>
    )
}

const CvList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            exporter={exporter}
            filters={<_Filter/>}
            title="Резюме"
            sort={{field: 'id', order: 'DESC'}}
            empty={<EmptyList title={'Нет резюме'}/>}
        >
            <Datagrid bulkActionButtons={false}>
                <IdShowButtonField type={'show'} source={'id'} label={'ID'}/>
                <SelectField source={'moderationStatus'} label={'Статус модерации'} choices={ModerationStatusList}/>
                <VisibilityField source={'published'} label={'Опубликован'}/>
                <ProfileLinkField label={'Профиль'} source={'profile'}/>
                <TextField source={'firstName'} label={'Имя'}/>
                <TextField source={'lastName'} label={'Фамилия'}/>
                <TextField source={'patronymic'} label={'Отчество'}/>
                <TextField source={'birthday'} label={'ДР'}/>
                <TextField source={'gender'} label={'Пол'}/>
                <TextField source={'salary'} label={'Зарплата'}/>
                <DateField source={'createdAt'} label={'Создано'} showTime={true}/>
                <ActionFields/>
            </Datagrid>
        </List>
    )
}

export default CvList
