import React from 'react'
import {Admin} from '@glm/ra-enterprise'
import {Resource} from 'src/components/Resource'
import {AuthProvider} from 'src/common/providers/AuthProvider'
import {dataProvider} from 'src/common/providers/dataProvider'
import russianMessages from 'ra-language-russian'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import {darkTheme, lightTheme} from 'src/style'
//PlopImport

import BillingMethod from 'src/resources/billing-method'

import Subscription from 'src/resources/subscription'

import Payment from 'src/resources/payment'
import Tariff from 'src/resources/tariff'
import AiVacancyGenRequest from 'src/resources/ai-vacancy-gen-request'
import AiCvEvaluationRequest from 'src/resources/ai-cv-evaluation-request'
import AiCvRequest from 'src/resources/ai-cv-request'

import skill from 'src/resources/skill'
import cv from 'src/resources/cv'
import vacancy from 'src/resources/vacancy'
import admin from 'src/resources/admin'
import user from 'src/resources/user'
import profile from 'src/resources/profile'
import application from 'src/resources/application'
import chat from 'src/resources/chat'
import proposal from 'src/resources/proposal'
import page from 'src/resources/page'
import media from 'src/resources/media'
import benefit from 'src/resources/benefit'
import serviceCategory from 'src/resources/service-category'
import company from 'src/resources/company'
import keyword from 'src/resources/keyword'
import {AppLayout} from 'src/components/layout'
import {Dashboard, DashboardCompany, DashboardEmployee} from 'src/resources/dashboard'
const i18nProvider = polyglotI18nProvider(() => russianMessages, 'en')

const App = () => {
  console.log('darkTheme', darkTheme)
  return (
      <Admin
          lightTheme={lightTheme}
          darkTheme={darkTheme}
          i18nProvider={i18nProvider}
          dataProvider={dataProvider}
          authProvider={AuthProvider}
          layout={AppLayout}
          dashboard={Dashboard}
          requireAuth
      >
        <Resource key={'dashboard/companies'} name="dashboard/companies" list={DashboardCompany} options={{label: 'Dashboard'}}/>
        <Resource key={'dashboard/employees'} name="dashboard/employees" list={DashboardEmployee} options={{label: 'Dashboard'}}/>

        <Resource key={'skill'} name="skill" {...skill} options={{label: 'Skills'}}/>
        <Resource key={'benefit'} name="benefit" {...benefit} options={{label: 'Benefits'}}/>
        <Resource key={'company'} name="company" {...company} options={{label: 'Companies'}}/>
        <Resource key={'keyword'} name="keyword" {...keyword} options={{label: 'Keywords'}}/>

        <Resource key={'cv'} name="cv" {...cv} options={{label: 'Резюме'}}/>
        <Resource key={'cv-approved'} name="cv-approved" {...cv} options={{label: 'Резюме'}}/>
        <Resource key={'cv-moderation'} name="cv-moderation" {...cv} options={{label: 'Резюме'}}/>
        <Resource key={'vacancy'} name="vacancy" {...vacancy} options={{label: 'Вакансии'}}/>
        <Resource key={'vacancy-approved'} name="vacancy-approved" {...vacancy} options={{label: 'Вакансии'}}/>
        <Resource key={'vacancy-moderation'} name="vacancy-moderation" {...vacancy} options={{label: 'Вакансии'}}/>
        <Resource key={'application'} name="application" {...application} options={{label: 'Отклики'}}/>
        <Resource key={'proposal'} name="proposal" {...proposal} options={{label: 'Офферы'}}/>
        <Resource key={'chat'} name="chat" {...chat} options={{label: 'Чаты'}}/>
        <Resource key={'chat-message'} name="chat-message"/>
        <Resource key={'user'} name="user" {...user} options={{label: 'Пользователи'}}/>
        <Resource key={'profile'} name="profile" {...profile} options={{label: 'Профили'}}/>
        <Resource key={'profile-hirer'} name="profile-hirer" {...profile} options={{label: 'Профили'}}/>
        <Resource key={'profile-employee'} name="profile-employee" {...profile} options={{label: 'Профили'}}/>
        <Resource key={'page'} name="page" {...page} options={{label: 'Текстовые страницы'}}/>
        <Resource key={'ai-cv-request'} name="ai-cv-request" {...AiCvRequest} options={{label: 'Ai запросы резюме'}}/>
        <Resource key={'ai-cv-evaluation-request'} name="ai-cv-evaluation-request" {...AiCvEvaluationRequest} options={{label: 'Ai запросы оценки'}}/>
        <Resource key={'ai-vacancy-gen-request'} name="ai-vacancy-gen-request" {...AiVacancyGenRequest} options={{label: 'Ai запросы генерации'}}/>
        <Resource key={'service-category'} name="service-category" {...serviceCategory}
                  options={{label: 'Категории'}}/>
        <Resource key={'asset'} name="asset" {...media} options={{label: 'Файлы'}}/>
        <Resource key={'admin'} name="admin" {...admin} options={{label: 'Администраторы'}}/>
        <Resource key={'tariff'} name="tariff" {...Tariff} options={{label: 'Тарифы'}}/>
        <Resource key={'payment'} name="payment" {...Payment} options={{label: 'Платежи'}}/>
        <Resource key={'subscription'} name="subscription" {...Subscription} options={{label: 'Подписки'}}/>
        <Resource key={'billing-method'} name="billing-method" {...BillingMethod} options={{label: 'Способы оплаты'}}/>
        <Resource key={'location'} name="location"/>
        <Resource key={'profile-impersonate-token'} name="profile-impersonate-token"/>
        {/*PlopResource*/}
      </Admin>
  )
}

export default App
