import * as React from 'react'
import {FC} from 'react'
import {
  Show,
  TabbedShowLayout,
  DateField,
  Tab,
  EditProps,
  TextField, SelectField,
} from 'react-admin'
import ProfileLinkField from 'src/components/fields/ProfileLinkField'
import CvLinkField from 'src/components/fields/CvLinkField'
import VacancyLinkField from 'src/components/fields/VacancyLinkField'
import {ApplicationStatusList} from 'src/types/enum/ApplicationStatus'

interface TitleProps {
  record?: any;
}

const Title: FC<TitleProps> = ({record}) =>
 <span>Отклик #{record?.id}</span>

const UserShow: FC<EditProps> = props => {
  return (<Show {...props} title={<Title/>}>
    <TabbedShowLayout>
      <Tab label="Информация">
        <TextField source={'id'} label={'ID'}/>
        <SelectField source={'status'} label={'Статус'} choices={ApplicationStatusList}/>
        <VacancyLinkField source={'vacancy'} label={'Вакансия'}/>
        <CvLinkField source={'cv'} label={'Резюме'}/>
        <ProfileLinkField label={'Профиль'} source={'profile'}/>
        <DateField source={'createdAt'} label={'Создан'} showTime/>
        <DateField source={'updatedAt'} label={'Обновлен'} showTime/>
        <TextField source={'coverLetter'} label={'Сообщение'}/>
      </Tab>
    </TabbedShowLayout>
  </Show>)
}

export default UserShow