import * as React from 'react'
import {FC} from 'react'
import {
    Filter,
    List,
    ListProps,
    Datagrid,
    SelectField, FunctionField,
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import TransTextField from 'src/components/fields/TransTextField'
import {PurchaseTypeList} from 'src/types/enum/PurchaseType'
import { SubscriptionKindList} from 'src/types/enum/SubscriptionKind'

const exporter = (posts: any[]) => {
    return csvExporter({
        columns: {
            'createdAt': 'Создано',
        },
        data: posts.map((item: any) => ({
            ...item,

            createdAt: formatCsvDate(item.createdAt),
            updatedAt: formatCsvDate(item.updatedAt)
        })),
        fileName: 'Tariff'
    })
}

const _Filter = (props: any) => {
    return (
        <Filter {...props}>

        </Filter>
    )
}

const TariffList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            exporter={exporter}
            filters={<_Filter/>}
            title="Tariffs"
            sort={{field: 'id', order: 'DESC'}}
            empty={<EmptyList title={'No Data'} description={'You can create'}
                              buttonText={'Create'}/>}
        >
            <Datagrid bulkActionButtons={false}>
                <IdShowButtonField source={'id'} label={'ID'}/>
                <TransTextField source={'name'} label={'Name'}/>
                <SelectField source={'purchaseType'} label={'Type'} choices={PurchaseTypeList}/>
                <SelectField source={'kind'} label={'Kind'} choices={SubscriptionKindList}/>
                <FunctionField source={'jobsLimit'} label={'Limits (J/M/E)'} render={(i: any) => [i.jobsUnlimited  ? '∞' : i.jobsLimit , i.managersUnlimited  ? '∞' : i.managersLimit, i.evaluationUnlimited  ? '∞' :  i.evaluationLimit].join('/')}/>
            </Datagrid>
        </List>
    )
}

export default TariffList
