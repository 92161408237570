import * as React from 'react'
import {
  Pagination,
  DateField,
  TextField,
  Datagrid,
  ReferenceManyField, SelectField,
} from 'react-admin'
import ProfileLinkField from 'src/components/fields/ProfileLinkField'
import {ApplicationStatusList} from 'src/types/enum/ApplicationStatus'
import CvLinkField from 'src/components/fields/CvLinkField'

interface TitleProps {
  record?: any;
}

export const VacancyApplicationList = (props: any) => {
  const {record} = props
  return (
    <ReferenceManyField {...props} reference="application" target="vacancyId" addLabel={false}
                        sort={{field: 'id', order: 'ASC'}} pagination={<Pagination/>} perPage={50}>
      <Datagrid>
        <TextField source={'id'} label={'ID'}/>
        <SelectField source={'status'} label={'Статус'} choices={ApplicationStatusList}/>
        <ProfileLinkField label={'Профиль'} source={'profile'}/>
        <CvLinkField label={'Резюме'} source={'cv'}/>
        <DateField source={'createdAt'} label={'Создан'} showTime/>
      </Datagrid>
    </ReferenceManyField>)
}