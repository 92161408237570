export enum ProfileType {
    Employee = 'employee',
    Hirer = 'hirer',
}
export const ProfileTypeList = [
    {id: ProfileType.Employee, name: 'Соискатель'},
    {id: ProfileType.Hirer, name: 'Работодатель'}
]
export enum Gender {
    male = 'male',
    female = 'female',
}
export const GenderList = [
    {id: Gender.male, name: 'Муж'},
    {id: Gender.female, name: 'Жен'}
]