import ListIcon from '@mui/icons-material/List'
import ChatList from 'src/resources/chat/ChatList'
import ChatEdit from 'src/resources/chat/ChatEdit'
import ChatShow from 'src/resources/chat/ChatShow'

export default {
  show: ChatShow,
  edit: ChatEdit,
  list: ChatList,
  icon: ListIcon,
}