import ListIcon from '@mui/icons-material/List'
import ProposalList from 'src/resources/proposal/ProposalList'
import ProposalEdit from 'src/resources/proposal/ProposalEdit'
import ProposalCreate from 'src/resources/proposal/ProposalCreate'
import ProposalShow from 'src/resources/proposal/ProposalShow'

export default {
  create: ProposalCreate,
  edit: ProposalEdit,
  show: ProposalShow,
  list: ProposalList,
  icon: ListIcon,
}