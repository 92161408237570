import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import ServiceCategoryList from 'src/resources/service-category/ServiceCategoryList'
import ServiceCategoryEdit from 'src/resources/service-category/ServiceCategoryEdit'
import ServiceCategoryCreate from 'src/resources/service-category/ServiceCategoryCreate'

export default {
  edit: ServiceCategoryEdit,
  create: ServiceCategoryCreate,
  list: ServiceCategoryList,
  icon: LibraryBooksIcon,
}