import ListIcon from '@mui/icons-material/List'
import ApplicationList from 'src/resources/application/ApplicationList'
import ApplicationEdit from 'src/resources/application/ApplicationEdit'
import ApplicationShow from 'src/resources/application/ApplicationShow'

export default {
  show: ApplicationShow,
  edit: ApplicationEdit,
  list: ApplicationList,
  icon: ListIcon,
}