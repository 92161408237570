import {AutocompleteInput, ReferenceInput} from 'react-admin'
import * as React from 'react'
import Formatter from 'src/utils/formatters'


interface Props{
    source?: string
    label?: string
    disabled?: boolean;
    allowEmpty?: boolean
    resettable?: boolean
    alwaysOn?: boolean
    fullWidth?: boolean
}
const ProfileInput = (props: Props) => {
return ( <ReferenceInput
    allowEmpty={props.allowEmpty}
    source={props.source ?? 'profileId'} reference="profile" variant={'outlined'}
    sort={{field: 'id', order: 'DESC'}} perPage={100} {...props as any}>
    <AutocompleteInput noOptionsText="-"  filterToQuery={(q: string) => ({'user.phone:$contL&user.email:$contL&firstName:$contL&lastName:$contL&telegramNickname:$contL&company:$contL': q})} optionText={(i) => Formatter.formatProfileName(i)} label={props.label ?? 'Профиль'}/>
</ReferenceInput>)
}

export default ProfileInput