import * as React from 'react'
import {FC} from 'react'
import {
  Show,
  TabbedShowLayout,
  DateField,
  Tab,
  EditProps,
  TopToolbar,
  TextField, SelectField,
} from 'react-admin'
import {ProfileType, ProfileTypeList} from 'src/types/interfaces/IProfile'
import {UserVacancyList} from 'src/resources/profile/cv/UserVacancyList'
import {UserCvList} from 'src/resources/profile/cv/UserCvList'
import Formatter from 'src/utils/formatters'
import {ProfileManagerList} from 'src/resources/profile/cv/ProfileManagerList'
import ProfileLinkField from 'src/components/fields/ProfileLinkField'
import {useRecordContext} from 'ra-core'
import ImpersonateButton from 'src/resources/profile/ImpersonateButton'
interface TitleProps {
  record?: any;
}

const Actions = () => {
  const record = useRecordContext()
  return (
      <TopToolbar>
        <ImpersonateButton profileId={record.id} buttonView={'button'}/>
      </TopToolbar>
  )
}
const Title: FC<TitleProps> = () => {
  const record = useRecordContext()
  return (<span>Профиль #{record?.id} {record && Formatter.formatProfileName(record)}</span>)
}

const Tabs = () => {
  const record = useRecordContext()
  return (<TabbedShowLayout>
    <Tab label="Информация">
      <TextField source={'id'} label={'ID'}/>
      <SelectField source={'profileType'} label={'Активность'} choices={ProfileTypeList}/>
      <ProfileLinkField source={'lastName'} format={'userName'} label={'ФИО'}/>
      <TextField source={'company.name'} label={'Компания'}/>
      <TextField source={'referralCode'} label={'Referral Code'}/>
      <DateField source={'createdAt'} label={'Регистрация'} showTime={true} />
    </Tab>
    {record.profileType === ProfileType.Hirer && <Tab label="Вакансии" path="evacancies">
      <UserVacancyList />
    </Tab>}
    {record.profileType === ProfileType.Employee && <Tab label="Резюме" path="ecv">
      <UserCvList />
    </Tab>}
    {record.profileType === ProfileType.Hirer && <Tab label="Менеджеры" path="managers">
      <ProfileManagerList />
    </Tab>}

  </TabbedShowLayout>)
}
const ProfileShow: FC<EditProps> = props => {
  return (<Show {...props}  actions={<Actions/>}>
      <Tabs/>
  </Show>)
}

export default ProfileShow
