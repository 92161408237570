import * as React from 'react'
import {FC, useState} from 'react'
import { Box, useMediaQuery, Theme } from '@mui/material'
import {
    Filter,
    FilterProps,
    ListBase,
    ListProps,
    Pagination, RaRecord,
    SearchInput,
    Title,
    TopToolbar,
    useListContext,
} from 'react-admin'

import GridList from './GridList'
import {ModalCreateButton} from 'src/components/Modal/ModalButton'
import ModalMedia from 'src/components/ModalMedia'
import DateRangeFilterInput from 'src/components/inputs/DateRangeFilterInput'


export const _Filter: FC<Omit<FilterProps, 'children'>> = props => (
  <Filter {...props}>
    <SearchInput source="name:$contL" alwaysOn />
      <DateRangeFilterInput source={'createdAt:$between'} label="Загружен"
                            alwaysOn/>
  </Filter>
)

const ListActions: FC<any> = ({ isSmall }) => (
  <TopToolbar>
    <_Filter context="form" />
    <ModalCreateButton modal={<ModalMedia/>} />
  </TopToolbar>
)

const MediaList: FC<ListProps> = props => {
  const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'))
  return (
    <ListBase
      perPage={20}
      sort={{field: 'id', order: 'DESC'}}
    >
      <ProductListView isSmall={isSmall} />
    </ListBase>
  )
}

const ProductListView: FC<{ isSmall: boolean }> = ({ isSmall }) => {
  const { defaultTitle, ...rest } = useListContext()
  const [currentRecord, setCurrentRecord] = useState<RaRecord | null>(null)
  const handleSelect = (record: RaRecord) => {
      console.log('handleSelect111', record)
    setCurrentRecord(record)
  }
  return (
    <>
      <Title defaultTitle={defaultTitle} />
      <ListActions isSmall={isSmall} />
      {isSmall && (
        <Box m={1}>
          <_Filter context="form" />
        </Box>
      )}
      <Box display="flex">

        <Box >
          <GridList onSelect={handleSelect}/>
          <Pagination rowsPerPageOptions={[20, 30, 50, 100]} />
        </Box>
      </Box>
    </>
  )
}
export default MediaList