import ListIcon from '@mui/icons-material/List'
import PaymentList from 'src/resources/payment/PaymentList'
import PaymentEdit from 'src/resources/payment/PaymentEdit'
import PaymentCreate from 'src/resources/payment/PaymentCreate'

export default {
  create: PaymentCreate,
  edit: PaymentEdit,
  list: PaymentList,
  icon: ListIcon,
}