import * as React from 'react'
import { FC } from 'react'
import {
  Edit,
  EditProps,
  required,
} from 'react-admin'
import {getTranslation} from 'src/utils/translation'
import {ServiceCategoryForm} from 'src/resources/service-category/ServiceCategoryForm'
interface ProductTitleProps {
  record?: any;
}

const ProductTitle: FC<ProductTitleProps> = ({ record }) =>
  record ? <span>Категория {getTranslation(record)?.name}</span> : null


const ServiceCategoryEdit: FC<EditProps> = props => {

  return (
    <Edit {...props} mutationMode="pessimistic"  redirect={'list'}  title={<ProductTitle />}>
      <ServiceCategoryForm/>
    </Edit>
  )
}

const requiredValidate = [required()]

export default ServiceCategoryEdit