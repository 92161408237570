import * as React from 'react'
import {FC} from 'react'
import {
  Show,
  TabbedShowLayout,
  DateField,
  Tab,
  EditProps,
  TextField, FunctionField,
} from 'react-admin'
import {ChatMessageList} from 'src/resources/chat/ChatMessageList'
import ProfileLinkField from 'src/components/fields/ProfileLinkField'

interface TitleProps {
  record?: any;
}

const Title: FC<TitleProps> = ({record}) =>
 <span>Чат #{record?.id}</span>

const UserShow: FC<EditProps> = props => {
  return (<Show {...props} title={<Title/>}>
    <TabbedShowLayout>
      <Tab label="Информация">
        <TextField source={'id'} label={'ID'}/>
        <TextField source={'name'} label={'Название'} />
        <FunctionField source={'IsGroup'} label={'Группа'} render={(i: any) => i.isGroup ? 'Да' : ''} />
        <ProfileLinkField label={'Профиль 1'} source={'profile'}/>
        <ProfileLinkField label={'Профиль 2'} source={'participant'}/>
        <DateField source={'lastMessageAt'} label={'Последнее сообщение'} showTime/>
        <DateField source={'createdAt'} label={'Создан'} showTime/>
      </Tab>
      <Tab label="Сообщения" path="messages">
        <ChatMessageList />
      </Tab>

    </TabbedShowLayout>
  </Show>)
}

export default UserShow