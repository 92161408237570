import ListIcon from '@mui/icons-material/List'
import BillingMethodList from 'src/resources/billing-method/BillingMethodList'
import BillingMethodEdit from 'src/resources/billing-method/BillingMethodEdit'
import BillingMethodCreate from 'src/resources/billing-method/BillingMethodCreate'

export default {
  create: BillingMethodCreate,
  edit: BillingMethodEdit,
  list: BillingMethodList,
  icon: ListIcon,
}