import * as React from 'react'
import {FC} from 'react'
import {
  Create,
  required, CreateProps,
} from 'react-admin'
import {ServiceCategoryForm} from 'src/resources/service-category/ServiceCategoryForm'

interface ProductTitleProps {
  record?: any;
}

const ProductTitle: FC<ProductTitleProps> = ({record}) => <span>Новая категория</span>

const ServiceCategoryCreate: FC<CreateProps> = props => {

  return (
    <Create {...props} redirect={'list'}  title={<ProductTitle/>}>
      <ServiceCategoryForm isNew/>
    </Create>
  )
}

const requiredValidate = [required()]

export default ServiceCategoryCreate