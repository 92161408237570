import React, {useState} from 'react'
import styles from './index.module.scss'
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import DialogContent from '@mui/material/DialogContent'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'

import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import AppBar from '@mui/material/AppBar'
import classNames from 'classnames'
enum ViewType{
  Desktop = 'desktop',
  Mobile = 'mobile'
}
interface Props{
  html: string
  onClose: () => void,
  isShown: boolean
}

export const RichEditorPreviewModal = (props: Props) => {
  const {html} = props
  const [viewType, setViewType] = useState<ViewType>(ViewType.Desktop)
  const handleChangeViewType = (
      event: React.MouseEvent<HTMLElement>,
      newViewType: ViewType,
  ) => {
    setViewType(newViewType)
  }
  return <Dialog
    fullScreen
    fullWidth
    open={props.isShown}
    onClose={props.onClose}
    aria-label={'Предпросмотр'}
  >
    <AppBar sx={{ position: 'relative' }}>
      <Toolbar>
        <IconButton
            edge="start"
            color="inherit"
            onClick={props.onClose}
            aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
          Предпросмотр
        </Typography>
        <ToggleButtonGroup
            color="primary"
            value={viewType}
            exclusive
            onChange={handleChangeViewType}
            aria-label="Вид"
        >
          <ToggleButton value={ViewType.Desktop}>Desktop</ToggleButton>
          <ToggleButton value={ViewType.Mobile}>Mobile</ToggleButton>
        </ToggleButtonGroup>
      </Toolbar>
    </AppBar>
    <DialogContent>
      <Box className={viewType === ViewType.Desktop ? styles.desktop : styles.mobile}>
        <Box className={classNames(styles.preview, viewType === ViewType.Desktop ? 'html-preview-desktop' : 'html-preview-mobile')}  dangerouslySetInnerHTML={{__html: html}}/>
      </Box>
    </DialogContent>

  </Dialog>


}
