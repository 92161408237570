import {MultiLevelMenu} from '@glm/ra-navigation'
import {
    Cogs,
    ChartDonutVariant,
    AccountTieHat,
    FileAccount,
    FormatListBulleted,
    ShieldAccount
} from 'mdi-material-ui'
import React from 'react'

export const MyMenu = () => (
    <MultiLevelMenu>
        <MultiLevelMenu.Item name="dashboard" to={'/'} label="Dashboard" icon={<Cogs/>}>
            <MultiLevelMenu.Item name="Companies" to="/dashboard/companies" label="Companies" icon={<FormatListBulleted/>}/>
            <MultiLevelMenu.Item name="Employees" to="/dashboard/employees" label="Employees" icon={<FormatListBulleted/>}/>
        </MultiLevelMenu.Item>

        <MultiLevelMenu.Item name="skill" to={'/skill'} label="Settings" icon={<Cogs/>}>
            <MultiLevelMenu.Item name="skill" to="/skill" label="Skills" icon={<FormatListBulleted/>}/>
            <MultiLevelMenu.Item name="service-category" to="/service-category" label="Категории работ" icon={<FormatListBulleted/>}/>
            <MultiLevelMenu.Item name="benefit" to="/benefit" label="Виды преимуществ"
                                 icon={<FormatListBulleted/>}/>
            <MultiLevelMenu.Item name="keyword" to="/keyword" label="Keywords"
                                 icon={<FormatListBulleted/>}/>

        </MultiLevelMenu.Item>


        <MultiLevelMenu.Item name="profile" to={'/profile'} label="Профили" icon={<AccountTieHat/>}>
            <MultiLevelMenu.Item name="profile" to="/profile" label="Все профили" icon={<AccountTieHat/>}/>
            <MultiLevelMenu.Item name="profile-hirer" to="/profile-hirer" label="Работодатели" icon={<AccountTieHat/>}/>
            <MultiLevelMenu.Item name="profile-employee" to="/profile-employee" label="Соискатели" icon={<AccountTieHat/>}/>
            <MultiLevelMenu.Item name="company" to="/company" label="Компании" icon={<AccountTieHat/>}/>
            {/*<MultiLevelMenu.Item name="user" to="/user" label="Пользователи" icon={<AccountTieHat/>}/>*/}
            {/*<MultiLevelMenu.Item name="character" to="/character" label="Перс. страницы" icon={<AccountCowboyHat/>}/>*/}

        </MultiLevelMenu.Item>

        <MultiLevelMenu.Item name="hr" to={'/hr'} label="Hr" icon={<AccountTieHat/>}>
            <MultiLevelMenu.Item name="cv" to="/cv" label="Резюме" icon={<FileAccount/>}/>
            <MultiLevelMenu.Item name="vacancy" to="/vacancy" label="Вакансии" icon={<ChartDonutVariant/>}/>

            {/*<MultiLevelMenu.Item name="application" to="/application" label="Отклики" icon={<ChartDonutVariant/>}/>*/}
        </MultiLevelMenu.Item>
        {/* <MultiLevelMenu.Item name="notification" to="/notification" label="Уведомления" icon={<Api/>}/>>*/}
        <MultiLevelMenu.Item name="admin" to="/admin" label="Администраторы" icon={<ShieldAccount/>}/>
        <MultiLevelMenu.Item name="ai-cv-request" to="/ai-cv-request" label="AI запросы резюме" icon={<ShieldAccount/>}/>
        <MultiLevelMenu.Item name="ai-cv-evaluation-request" to="/ai-cv-evaluation-request" label="AI запросы оценки" icon={<ShieldAccount/>}/>
        <MultiLevelMenu.Item name="ai-vacancy-gen-request" to="/ai-vacancy-gen-request" label="AI запросы генерации" icon={<ShieldAccount/>}/>
        <MultiLevelMenu.Item name="tariff" to="/tariff" label="Тарифы" icon={<ShieldAccount/>}/>
        <MultiLevelMenu.Item name="subscription" to="/subscription" label="Подписки" icon={<ShieldAccount/>}/>
        <MultiLevelMenu.Item name="billing-method" to="/billing-method" label="Способы оплаты" icon={<ShieldAccount/>}/>
        <MultiLevelMenu.Item name="payment" to="/payment" label="Платежи" icon={<ShieldAccount/>}/>


    </MultiLevelMenu>
)
