import * as React from 'react'
import { FieldProps, RaRecord} from 'react-admin'
import LinkField from 'src/components/fields/LinkField'
import Formatter from 'src/utils/formatters'
interface Props<RecordType extends RaRecord = any> extends FieldProps {
}

const CvLinkField = (props: Props) => {

   return  <LinkField link={(record) => `/cv/${record[props.source ?? 'cv']?.id}/show`} source={props.source ?? 'profile.name'} value={(record) => Formatter.formatUserName(record[props.source ?? 'cv'])}  label={props.label}/>

}

export default CvLinkField
