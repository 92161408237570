import React from 'react'
import {
  required,
  TextInput, useDataProvider, useRefresh,SelectInput
} from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import {FieldValues} from 'react-hook-form'
import { ProfileTypeList} from 'src/types/interfaces/IProfile'

export const UserProfileEditForm = (props: any) => {
  const { record } = props
  const refresh = useRefresh()
  const dataProvider = useDataProvider()

  const handleSubmit = async (data: FieldValues) => {
    await dataProvider.update('profile', {id: record.id, data, previousData: record})
    refresh()
    return true
  }

  return <ModalForm
    {...props}
    title={'Изменить профиль'}
    handleClose={props.onClose}
    save={handleSubmit}
    record={props.record}

  >
    <SelectInput source={'profileType'} label={'Тип'} choices={ProfileTypeList} disabled variant={'outlined'} validate={required()} />
    <TextInput source={'firstName'} label={'Имя'} variant={'outlined'} validate={required()}/>
    <TextInput source={'lastName'} label={'Фамилия'} variant={'outlined'} validate={required()}/>
    <TextInput source={'patronymic'} label={'Отчество'} variant={'outlined'} />
    <TextInput source={'telegramNickname'} label={'Ник ТГ'} variant={'outlined'} />
    <TextInput source={'company'} label={'Компания'} variant={'outlined'} />
    <TextInput source={'country.geoname'} label={'Страна'} variant={'outlined'} />
    <TextInput source={'city.geoname'} label={'Город'} variant={'outlined'} />
  </ModalForm>
}
