import ListIcon from '@mui/icons-material/List'
import SubscriptionList from 'src/resources/subscription/SubscriptionList'
import SubscriptionEdit from 'src/resources/subscription/SubscriptionEdit'
import SubscriptionCreate from 'src/resources/subscription/SubscriptionCreate'

export default {
  create: SubscriptionCreate,
  edit: SubscriptionEdit,
  list: SubscriptionList,
  icon: ListIcon,
}