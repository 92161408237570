
export enum Schedule {
    fullDay = 'fullDay',
    flexible = 'flexible',
    remote = 'remote',
}

export const ScheduleList = [
    {id: Schedule.fullDay, name: 'Полный день'},
    {id: Schedule.flexible, name: 'Гибкий график'},
    {id: Schedule.remote, name: 'Удаленно'}
]