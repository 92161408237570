
export enum SubscriptionPeriod {
  OneMonth = 1,
  ThreeMonths = 3,
  Year = 12,
}
export const SubscriptionPeriodListMonth = [
  {id: SubscriptionPeriod.OneMonth, name: '1 month'},
  {id: SubscriptionPeriod.ThreeMonths, name: '3 months'},
  {id: SubscriptionPeriod.Year, name: '1 year'},
]
export const SubscriptionPeriodListWeek = [1,2,3,4].map(id => ({id, name: `${id}w`}))

export const SubscriptionPeriodListDay = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24, 25,26,27,28,29,30].map(id => ({id, name: `${id}d`}))

export const SubscriptionPeriodListHour = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map(id => ({id, name: `${id}h`}))
