import ListIcon from '@mui/icons-material/List'
import SkillList from 'src/resources/skill/SkillList'
import SkillEdit from 'src/resources/skill/SkillEdit'
import SkillCreate from 'src/resources/skill/SkillCreate'

export default {
  create: SkillCreate,
  edit: SkillEdit,
  list: SkillList,
  icon: ListIcon,
}