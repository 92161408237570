import * as React from 'react'
import {RecordContextProvider, useDataProvider, useRefresh} from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import {FieldValues} from 'react-hook-form'
import {SubscriptionEditForm} from 'src/resources/company/subscription/SubscriptionEditForm'
import {SubscriptionStatus} from 'src/types/enum/SubscriptionStatus'
import {SubscriptionKind} from 'src/types/enum/SubscriptionKind'
import {SubscriptionType} from 'src/types/enum/SubscriptionType'
interface Props{
    refresh?: boolean
    isShown?: boolean
    id?: number
    mainRecord: Record<any, any>
    onClose?: () => void
}
export const SubscriptionCreateModal = (props: Props) => {
    const refresh = useRefresh()
    const dataProvider = useDataProvider()
    const handleSubmit = async (data: FieldValues) => {
         const res = await dataProvider.create('subscription', {data: {...data, companyId: props.mainRecord.id}})
            refresh()

        if(props.onClose){
            props.onClose()
        }
        return true
    }

    return <RecordContextProvider value={{}}>
        <ModalForm
        isShown
        defaultValues={{status: SubscriptionStatus.Draft, kind: SubscriptionKind.Enterprise, type: SubscriptionType.Subscription}}
        resource={'subscription'}
        title={'New Subscription'}
        onClose={props.onClose}
        save={handleSubmit}
    >
        {<SubscriptionEditForm />}
    </ModalForm></RecordContextProvider>
}
SubscriptionCreateModal.defaults = {
    refresh: true
}
