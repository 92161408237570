import * as React from 'react'
import {FC} from 'react'
import {
  Edit,
  EditProps,
} from 'react-admin'
import {getTranslation} from 'src/utils/translation'
import {PageForm} from 'src/resources/page/PageForm'
interface TitleProps {
  record?: any;
}
const Title: FC<TitleProps> = ({record}) =>
  record ? <span>Текстовая страница {getTranslation(record)?.title}</span> : null

const PageEdit: FC<EditProps> = props => {
  return (
    <Edit {...props} mutationMode="pessimistic" title={<Title/>}>
     <PageForm/>
    </Edit>
  )
}
export default PageEdit