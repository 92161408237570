import ListIcon from '@mui/icons-material/List'
import KeywordList from 'src/resources/keyword/KeywordList'
import KeywordEdit from 'src/resources/keyword/KeywordEdit'
import KeywordCreate from 'src/resources/keyword/KeywordCreate'

export default {
  create: KeywordCreate,
  edit: KeywordEdit,
  list: KeywordList,
  icon: ListIcon,
}