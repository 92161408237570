import ListIcon from '@mui/icons-material/List'
import AiCvEvaluationRequestList from 'src/resources/ai-cv-evaluation-request/AiCvEvaluationRequestList'
import AiCvEvaluationRequestEdit from 'src/resources/ai-cv-evaluation-request/AiCvEvaluationRequestEdit'
import AiCvEvaluationRequestCreate from 'src/resources/ai-cv-evaluation-request/AiCvEvaluationRequestCreate'
import AiCvEvaluationRequestShow from 'src/resources/ai-cv-evaluation-request/AiCvEvaluationRequestShow'

export default {
  create: AiCvEvaluationRequestCreate,
  edit: AiCvEvaluationRequestEdit,
  list: AiCvEvaluationRequestList,
  show: AiCvEvaluationRequestShow,
  icon: ListIcon,
}
