export interface IFocalPoint{
  x: number
  y: number
}
export enum AssetType{
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
  AUDIO = 'AUDIO',
}
export interface IAsset{
  id: number
  type: AssetType
  source: string
  name: string
  focalPoint: IFocalPoint
}