
export enum Experience {
    noExperience = 'noExperience',
    from1to3 = 'from1to3',
    from3to6 = 'from3to6',
    from6 = 'from6',
}
export const ExperienceList = [
    {id: Experience.noExperience, name: 'Без опыта'},
    {id: Experience.from1to3, name: 'от 1-3 лет'},
    {id: Experience.from3to6, name: 'От 3-6 лет'},
    {id: Experience.from6, name: 'От 6 лет'},
]
