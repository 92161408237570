import * as React from 'react'
import {useRecordContext} from 'ra-core'
import { FieldProps, RaRecord} from 'react-admin'
import get from 'lodash/get'
import ReactJson from 'react-json-view'
type Callback = (record: RaRecord) => string
interface Props<RecordType extends RaRecord = any> extends FieldProps {

}

const JsonField = (props: Props) => {
    const record = useRecordContext(props)
    const value = get(record, props.source ?? '')
    return (
        <ReactJson src={value} />
    )
}


export default JsonField
