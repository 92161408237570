import * as React from 'react'
import get from 'lodash/get'
import Typography from '@mui/material/Typography'
import { useRecordContext } from 'ra-core'
import {FieldProps, RaRecord} from 'react-admin'
import {getMediaPath} from 'src/utils/media'
import styles from './index.module.scss'


export interface Props<RecordType extends RaRecord = any> extends FieldProps {
  src?: string;
  title?: string;
  classes?: object;
}

const AssetField = (props: Props) => {
  const {
    className,
    classes: classesOverride,
    emptyText,
    source,
    src,
    title,
    ...rest
  } = props
  const record = useRecordContext(props)
  const recordValue = get(record, source ?? '')
  const sourceValue = recordValue ? (Array.isArray(recordValue) ? recordValue.map(i => i) : getMediaPath(recordValue?.source)) : null

  if (!sourceValue) {
    return emptyText ? (
      <Typography
        component="span"
        variant="body2"
        className={className}
      >
        {emptyText}
      </Typography>
    ) : (
      <div className={className}/>
    )
  }
  console.log('AssetRecord', sourceValue)

  if (Array.isArray(sourceValue)) {
    return (
      <ul
        className={styles.list}
      >
        {sourceValue.map((file, index) => {
          return (
            <li key={index}>
              {file?.type === 'IMAGE' && <img
                src={`${getMediaPath(file.source)}?preset=tiny`}
                className={styles.image}
              />}
              {file?.type !== 'IMAGE' && <a href={getMediaPath(file.source)} target={'_blank'} rel="noreferrer">{file?.originalname}</a>}
            </li>
          )
        })}
      </ul>
    )
  }


  return (
    <div className={className} >
      {recordValue?.type === 'IMAGE' && <img
          src={`${sourceValue}?preset=tiny`}
          className={styles.image}
      />}
      {recordValue?.type !== 'IMAGE' && <a href={getMediaPath(recordValue?.source)} target={'_blank'} rel="noreferrer">{recordValue?.name}</a>}

    </div>
  )
}

AssetField.displayName = 'ImageField'

export default AssetField
