import ListIcon from '@mui/icons-material/List'
import ProfileList from 'src/resources/profile/ProfileList'
import ProfileEdit from 'src/resources/profile/ProfileEdit'
import ProfileCreate from 'src/resources/profile/ProfileCreate'
import ProfileShow from 'src/resources/profile/ProfileShow'

export default {
  create: ProfileCreate,
  edit: ProfileEdit,
  show: ProfileShow,
  list: ProfileList,
  icon: ListIcon,
}