import React, {FC, ReactElement, useState} from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {isArray} from 'lodash'
interface Props {
  children: ReactElement | ReactElement[];
  label?: string;
  type?: 'iconButton' | 'button'
  icon?: ReactElement,
  modal?: ReactElement
}

export const DropdownMenu: FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [open, setOpen] = useState(false)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
      setOpen(true)
  }
  const handleClose = () => {
  //  setAnchorEl(null)
      setOpen(false)
  }
  console.log('anchorEl', anchorEl)

  return (
      <div>
        <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
        >
          <MoreVertIcon color={'primary'} />
        </IconButton>
        <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            keepMounted
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}

        >

            {(isArray(props.children) ? props.children : [props.children]).map(i => React.cloneElement(i, {
                onClose: handleClose,
            }))}
        </Menu>
      </div>
  )
}

