import * as React from 'react'
import {
    FormDataConsumer,
    RecordContextProvider, required, SelectInput,
    useDataProvider,
    useRefresh
} from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import {FieldValues} from 'react-hook-form'
import {useRecordContext} from 'ra-core'
import {ModerationStatus, ModerationStatusList} from 'src/types/enum/ModerationStatus'
import {ModerationRejectReasonList} from 'src/types/enum/ModerationRejectReason'
interface FormProps{
    record: any,
    refresh: () => void,
    onClose?: () => void
}
const Form = (props: FormProps) => {
    return (<>
        <SelectInput source={'moderationStatus'} label={'Статус модерации'} choices={ModerationStatusList} fullWidth variant={'outlined'}/>
        <FormDataConsumer fullWidth={true} variant={'outlined'}>
            {({ formData, ...rest }) => formData.moderationStatus === ModerationStatus.Rejected  && (

                <SelectInput source={'moderationRejectReason'} label={'Причина отклонения'} choices={ModerationRejectReasonList} variant={'outlined'} fullWidth validate={required()} />

            )}
        </FormDataConsumer>

    </>)
}
interface Props{
    refresh?: boolean
    isShown?: boolean
    id?: number
    onClose?: () => void
}
export const VacancyStatusModal = (props: Props) => {
    const refresh = useRefresh()
    const dataProvider = useDataProvider()
    const record = useRecordContext()
    const handleSubmit = async (data: FieldValues) => {
        const res = await dataProvider.update('vacancy', {id: record.id, data: {...data}, previousData: record})

        if(props.refresh){
            refresh()
        }
        if(props.onClose){
            props.onClose()
        }
        return true
    }


    return <RecordContextProvider value={{}}>
        <ModalForm
        isShown
        defaultValues={{setPause: 30}}
        fullWidth={true}
        maxWidth={'xs'}
        resource={'area'}
        title={'Модерация'}
        onClose={props.onClose}
        save={handleSubmit}
    >
            <Form  record={record} refresh={refresh} onClose={props.onClose}/>

    </ModalForm></RecordContextProvider>
}
