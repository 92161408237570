import * as React from 'react'
import {FC} from 'react'
import {
  Create,
  EditProps,
} from 'react-admin'
import TariffForm from 'src/resources/tariff/TariffForm'

interface TitleProps {
  record?: any;
}

const Title = (props: TitleProps) =>
 <span>Новая Тариф</span>

const TariffCreate: FC<EditProps> = props => {
  return (
    <Create {...props} redirect="list"  title={<Title/>}>
      <TariffForm/>
    </Create>
  )
}

export default TariffCreate