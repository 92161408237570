import * as React from 'react'
import {FC} from 'react'
import {
    Filter,
    List,
    ListProps, SearchInput,
    Datagrid, TextField, SelectField, DateField, SelectInput,
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import {ModerationStatusList} from 'src/types/enum/ModerationStatus'
import DateRangeFilterInput from 'src/components/inputs/DateRangeFilterInput'
import ProfileInput from 'src/components/inputs/ProfileInput'
import CategoriesArrayInput from 'src/components/inputs/CategoriesArrayInput'
import {DropdownMenu} from 'src/components/DropdownMenu/DropdownMenu'
import {MenuModalButton} from 'src/components/Modal/MenuModalButton'
import {VacancyStatusModal} from 'src/resources/vacancy/VacancyStatusModal'

const ActionFields = () => {
    return (<DropdownMenu>
        <MenuModalButton label={'Модерация'} type={'iconButton'} key={'import'}
                         modal={<VacancyStatusModal refresh/>}>Модерация</MenuModalButton>
    </DropdownMenu>)
}
const exporter = (posts: any[]) => {
    return csvExporter({
        columns: {
            'createdAt': 'Создано',
        },
        data: posts.map((item: any) => ({
            ...item,

            createdAt: formatCsvDate(item.createdAt),
            updatedAt: formatCsvDate(item.updatedAt)
        })),
        fileName: 'Vacancy'
    })
}

const _Filter = (props: any) => {
    return (
        <Filter {...props}>
            <SearchInput source="name:$contL" variant={'outlined'} alwaysOn/>
            <ProfileInput source={'profileId'} label={'Профиль'} alwaysOn resettable/>
            <SelectInput source={'status'} label={'Статус модерации'} choices={ModerationStatusList} fullWidth variant={'outlined'}/>
            <CategoriesArrayInput source={'categories.id:$in'} label={'Категории'} />
            <DateRangeFilterInput source={'createdAt:$between'} label="Регистрация"
                                  alwaysOn/>
        </Filter>
    )
}

const VacancyList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            exporter={exporter}
            filters={<_Filter/>}
            title="Вакансии"
            sort={{field: 'id', order: 'DESC'}}
            empty={<EmptyList title={'Нет вакансий'} />}
        >
            <Datagrid>
                <IdShowButtonField type={'show'} source={'id'} label={'ID'}/>
                <VisibilityField source={'published'} label={'П'}/>
                <TextField source={'name'} label={'Имя'}/>
                <SelectField source={'moderationStatus'} label={'Статус'} choices={ModerationStatusList}/>
                <TextField source={'company.name'} label={'Компания'}/>
                <TextField source={'contactName'} label={'Контакты'}/>
                <TextField source={'country.name'} label={'Страна'}/>
                <TextField source={'city.name'} label={'Город'}/>
                <TextField source={'phone'} label={'Телефон'}/>
                <TextField source={'email'} label={'Email'}/>
                <DateField source={'createdAt'} label={'Создано'} showTime={true} />
                <ActionFields/>
            </Datagrid>
        </List>
    )
}

export default VacancyList
