import * as React from 'react'
import {FC} from 'react'
import GridListTile from '@mui/material/ImageListItem'
import MuiGridList from '@mui/material/ImageList'
import {
  useListContext, useTheme, RaRecord,
} from 'react-admin'
import {Breakpoint,     useTheme as     useThemeMui,} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

type BreakpointOrNull = Breakpoint | null;
import AssetListItem from 'src/components/AssetListItem'
import {IAsset} from 'src/components/types'
function useWidth() {
  const theme = useThemeMui()
  const keys: readonly Breakpoint[] = [...theme.breakpoints.keys ?? []].reverse()
  return (
      keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
        //  eslint-disable-next-line react-hooks/rules-of-hooks
        const matches = useMediaQuery(theme.breakpoints.up(key))
        return !output && matches ? key : output
      }, null) || 'xs'
  )
}
const getColsForWidth = (width: Breakpoint) => {
  if (width === 'xs') return 2
  if (width === 'sm') return 3
  if (width === 'md') return 3
  if (width === 'lg') return 5
  return 6
}

const times = (nbChildren: number, fn: (key: number) => any) =>
  Array.from({ length: nbChildren }, (_, key) => fn(key))

const LoadingGridList: FC<GridProps & { nbItems?: number }> = ({
                                                                 width,
                                                                 nbItems = 20,
                                                               }) => {
const [theme] = useTheme()
  return (
    <MuiGridList
      rowHeight={180}
      cols={getColsForWidth(width as Breakpoint)}
      sx={{ margin: 0,}}
    >
      {' '}
      {times(nbItems, key => (
        <GridListTile key={key}>
          <div style={{
           // backgroundColor: theme?.palette?.grey ? theme!.palette!.grey[300] : 'transparent',
            height: '100%',
          }}/>
        </GridListTile>
      ))}
    </MuiGridList>
  )
}
const LoadedGridList: FC<GridProps> = ({ width, onSelect }) => {
  const {  data, resource, ...rest } = useListContext()
  console.log('Data111', data, rest)
  if (!data) return null

  return (
    <MuiGridList
      rowHeight={180}
      cols={getColsForWidth(width as Breakpoint)}
      sx={{margin: 0}}
    >
      {data.map((item) => (
        <AssetListItem key={item.id} item={item as IAsset}/>
      ))}
    </MuiGridList>
  )
}

interface GridProps {
  onSelect?: (record: RaRecord) => void
  width?: string
}

const GridList: FC<GridProps> = ({ onSelect }) => {
  const { isLoading } = useListContext()
  const width = useWidth()
  return !isLoading ? (
    <LoadedGridList width={width} onSelect={onSelect}/>
  ) : (
    <LoadingGridList width={width} onSelect={onSelect}/>
  )
}

export default GridList