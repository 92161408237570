import * as React from 'react'
import {FC} from 'react'
import {
  EditProps, TopToolbar,
} from 'react-admin'
import VacancyForm from 'src/resources/vacancy/VacancyForm'
import {CustomEdit} from 'src/components/CustomEdit'
import {ModalButton} from 'src/components/Modal/ModalButton'
import {VacancyStatusModal} from 'src/resources/vacancy/VacancyStatusModal'

const Actions= () => {

  return (
      <TopToolbar>
        <ModalButton label={'Модерация'} modal={<VacancyStatusModal/>}/>
      </TopToolbar>
  )
}
interface TitleProps {
  record?: any;
}
const Title: FC<TitleProps> = ({record}) =>
  record ? <span>Вакансия {record.name}</span> : null

const VacancyEdit: FC<EditProps> = props => {
  return (
    <CustomEdit {...props} redirect="list" mutationMode="pessimistic" title={<Title/>} actions={<Actions/>}>
    <VacancyForm/>
    </CustomEdit>
  )
}
export default VacancyEdit
