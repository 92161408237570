import ListIcon from '@mui/icons-material/List'
import AiCvRequestList from 'src/resources/ai-cv-request/AiCvRequestList'
import AiCvRequestEdit from 'src/resources/ai-cv-request/AiCvRequestEdit'
import AiCvRequestCreate from 'src/resources/ai-cv-request/AiCvRequestCreate'
import AiCvRequestShow from 'src/resources/ai-cv-request/AiCvRequestShow'

export default {
  create: AiCvRequestCreate,
  edit: AiCvRequestEdit,
  show: AiCvRequestShow,
  list: AiCvRequestList,
  icon: ListIcon,
}
