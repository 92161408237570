
export enum VacancyLookType {
    NotLooking = 'notLooking',
    ActivelyLooking = 'activelyLooking',
    PassivelyLooking = 'passivelyLooking',
}

export const VacancyLookTypeList = [
    {id: VacancyLookType.NotLooking, name: 'Не ищет работу'},
    {id: VacancyLookType.ActivelyLooking, name: 'Собеседование'},
    {id: VacancyLookType.PassivelyLooking, name: 'Запрос информации'}
]