import * as React from 'react'
import {FC} from 'react'
import {
  EditProps,TopToolbar,
} from 'react-admin'
import CvForm from 'src/resources/cv/CvForm'
import {CustomEdit} from 'src/components/CustomEdit'
import {ModalButton} from 'src/components/Modal/ModalButton'
import {CvStatusModal} from 'src/resources/cv/CvStatusModal'

interface TitleProps {
  record?: any;
}
const Title: FC<TitleProps> = ({record}) =>
  record ? <span>Резюме {record.name}</span> : null

const Actions= () => {

  return (
      <TopToolbar>
        <ModalButton label={'Модерация'} modal={<CvStatusModal/>}/>
      </TopToolbar>
  )
}
const CvEdit: FC<EditProps> = props => {
  return (
    <CustomEdit {...props} redirect="list" mutationMode="pessimistic" title={<Title/>} actions={<Actions/>}>
    <CvForm/>
    </CustomEdit>
  )
}
export default CvEdit
