import ListIcon from '@mui/icons-material/List'
import BenefitList from 'src/resources/benefit/BenefitList'
import BenefitEdit from 'src/resources/benefit/BenefitEdit'
import BenefitCreate from 'src/resources/benefit/BenefitCreate'

export default {
  create: BenefitCreate,
  edit: BenefitEdit,
  list: BenefitList,
  icon: ListIcon,
}